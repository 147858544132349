import { Message } from '../message';

export const MESSAGES_PT_LANG = 'pt';
export const MESSAGES_PT_PT_LANG = 'pt-PT';

export const MESSAGES_PT = [
  new Message('user', 'Utilizador'),
  new Message('password', 'Palavra passe'),
  new Message('email', 'Email'),
  new Message('welcome', 'Seu espaço para gerenciar seu'),
  new Message('welcome2', 'presença, recursos e serviços'),
  new Message('menu_init', 'Início'),
  new Message('menu_room', 'Reserva de sala'),
  new Message('menu_profile', 'Perfil'),
  new Message('menu_available_rooms', 'Salas disponiveis'),
  new Message('menu_available_rooms_speed', 'Salas Speed disponiveis'),
  new Message('menu_descargas', 'download'),
  new Message('menu_security', 'Segurança'),
  new Message('menu_job_position', 'Cargo'),
  new Message('menu_dinning_position', 'Reserva de restaurante'),
  new Message('menu_assistance', 'Assistência'),
  new Message('menu_assistance_user', 'Assistência ao usuário'),
  new Message('menu_cantine', 'Sala de jantar'),
  new Message('menu_logout', 'Sair'),
  new Message('error_getEquipement', 'Erro ao obter o equiment do sala'),
  new Message('error_getAllEquipement', 'Erro ao obter o equiment'),
  new Message('error_getRoomDetail', 'Erro ao obter detalhes do sala'),
  new Message('rooms_reserve', 'Salas reservadas'),
  new Message('rooms_disable_reservation_title', 'Quartos liberados'),
  new Message('rooms_disable_reservation_description', 'Liberamos as salas de reunião durante a crise do COVID-19'),
  new Message('rooms_reserve_empty', 'Não há sala reservada'),
  new Message(
    'rooms_waiting_get_error',
    'Erro ao obter reservas e lista de espera do serviço'
  ),
  new Message('waiting_list', 'Lista de espera'),
  new Message('express_reservation_btn', 'Reserva expresso {0}'),
  new Message('room_reserve_btn', 'Reservar sala'),
  new Message('room_speed_reserve', 'Reservar Sala Speed'),
  new Message('room_reserve_byRoom_btn', 'Pesquisa de sala '),
  new Message('room_reserve_byHQ', 'Reservar sala em outra sede'),
  new Message('room_available', 'Acessível'),
  new Message('room_available_btn', 'Reservar'),
  new Message('room_edit_btn', 'Editar'),
  new Message('room_busy', 'Ocupada'),
  new Message('room_busy_user', 'Ocupada por {0}'),
  new Message(
    'express_resevation_success',
    'Você recebeu a sala {0} para a programação {1} ({2} - {3}'
  ),
  new Message('incorrect_login', 'Login  malfeito'),
  new Message('azure_token_renewal_error', 'Falha ao entrar no SSO, feche o pop-up e tente novamente'),
  new Message('error_forbidden', 'Sessão expirada'),
  new Message('error_required', '* Campo requerido'),
  new Message('error_numeric_required', '* Campo numérico e obrigatório'),
  new Message('error_license_required', '* Registro e campo obrigatório'),
  new Message('btn_view_rooms', 'Ver salas disponíveis'),
  new Message('error_nextBooking', 'Erro ao obter a última reserva'),
  new Message('home_events_title', 'PRÓXIMOS EVENTOS'),
  new Message('cancel_reservation_btn', 'Cancelar'),
  new Message('reservation_cancelled', 'Reserva cancelada com sucesso'),
  new Message('reservation_cancelled_error', 'Erro ao cancelar reserva'),
  new Message(
    'confirm_dialog_err',
    'Erro ao mostrar confirmação de cancelamento'
  ),
  new Message(
    'reservation_delete_msg',
    'Tem certeza de que deseja cancelar a nova reserva?'
  ),
  new Message('reservation_delete_msg_title', 'Cancelamento de reserva'),
  new Message(
    'cancel_waiting_msg',
    'Tem certeza de que deseja cancelar a espera?'
  ),
  new Message('cancel_waiting_msg_title', 'Cancelar espera'),
  new Message('cancel_waiting_successfully', 'Espera cancelada com succeso'),
  new Message('cancel_waiting_error', 'Erro ao cancelar espera'),
  new Message(
    'cancel_waiting_dialog_error',
    'Erro ao mostrar confirmação de cancelamento'
  ),
  new Message('express_create_error', 'Erro ao criar uma reserva expresso'),
  new Message('error_dalkonParking', 'Erro ao obter estacionamento'),
  new Message('home_parking_title', 'ESTACIONAMENTO'),
  new Message('parking_ceded', 'Cedida'),
  new Message('parking_resident', 'quadrado fixo'),
  new Message('parking_lot', 'Lugar designado para hoje'),
  new Message('no_parking', 'Sem estacionamento'),
  new Message('no_vehicle', 'Sem veículo'),
  new Message('session_expired', 'Sessão expirada'),
  new Message('schedule', 'Horário'),
  new Message('issue', 'Motivo'),
  new Message('periodicity', 'Periodicidade'),
  new Message('periodicity_none', 'Nenhuma'),
  new Message('periodicity_daily', 'Diário'),
  new Message('periodicity_weekly', 'Semanal'),
  new Message('periodicity_max_date', 'Data máxima'),
  new Message('equipment', 'Equipamento'),
  new Message('time_str', 'De {0} até {1}'),
  new Message('date', 'Data'),
  new Message('assistants', 'Assistentes'),
  new Message('from', 'De'),
  new Message('since', 'Até'),
  new Message('meeting_reason', 'Motivo da reunião'),
  new Message('date_from_greater', 'A data De deve ser maior que a data atual'),
  new Message('date_to_greater', 'A data Até deve ser maior que a data De'),
  new Message(
    'date_from_greater_actual',
    'A data de não pode ser menor que a data atual'
  ),
  new Message('date_from_in_day', 'A duração da reserva deve ser no mesmo dia'),
  new Message('duraton_greater', 'A duração não pode exceder o dia atual'),
  new Message(
    'date_max_periodicity_greater',
    'A data máxima da periodicidade deve ser maior que a data da reserva'
  ),
  new Message('no_available_rooms_msg', 'Não há salas disponíveis'),
  new Message(
    'no_available_rooms_equipment_msg',
    'Com o equipamento que você precisa'
  ),
  new Message('rooms_without_equip', 'Salas sem equipamento'),
  new Message('available_rooms_list_error', 'Erro ao obter salas disponíveis'),
  new Message(
    'room_reserved_msg',
    'Sala {0} reservada: adicionado no dia {1} de {2} a {3}'
  ),
  new Message(
    'room_speed_reserved_msg',
    'Sala {0} reservada: adicionado no dia {1} de {2} com duração {3} min.'
  ),
  new Message('room_reserved_multiple_msg', 'Reservas feitas corretamente'),
  new Message('reserve_creation_error', 'Erro ao criar reserva'),
  new Message('room_to_waiting', 'A reserva foi adicionada à lista de espera'),
  new Message(
    'room_to_waiting_error',
    'Erro ao adicionar reserva à lista de espera'
  ),
  new Message('room_detail_name_title', 'Sala'),
  new Message('room_conference_code_title', 'ID'),
  new Message('waiting_msg', '{0} de {1} até {2}'),
  new Message('reservation_reason_placeholder', 'Escreva um motivo'),
  new Message('edit_reservation_msg', 'Reserva atualizada com sucesso'),
  new Message('edit_reservation_error', 'Erro na edição da reserva'),
  new Message('reservation_in_progress', 'Em progresso'),
  new Message('name', 'Nome'),
  new Message('building', 'Edifício'),
  new Message('no_building', 'Sem edifício'),
  new Message('select', 'Seleccionar..'),
  new Message('all_dept', 'Todos os departamentos'),
  new Message('all_user_dept', 'Todos os usuários do departamento: '),
  new Message('save_profile_btn', 'Atualizar perfil'),
  new Message('save_password_btn', 'Atualizar  Palavra Passe'),
  new Message('change_password_btn', 'Alterar Palavra Passe'),
  new Message('save_building_btn', 'Gravar edifício'),
  new Message('buildings', 'Edifícios'),
  new Message('save_building_successfully', 'Edifício gravado com sucesso'),
  new Message('save_building_error', 'Erro ao gravar edifício'),
  new Message('error_getBuildings', 'Erro ao obter lista de edifício'),
  new Message('send_reservation_mail_btn', 'Alarme'),
  new Message('send_reservation_mail_success', 'Reserva enviada corretamente'),
  new Message('send_reservation_mail_error', 'Erro a enviar a reserva'),
  new Message('ok', 'OK'),
  new Message('reservation_listView_date', '{0} de {1}, de {2} a {3}'),
  new Message(
    'periodicity_types_load_error',
    'Erro ao carregar tipos de periocidade'
  ),
  new Message(
    'periodicity_empty_rooms',
    'Existem salas ocupadas no período selecionado'
  ),
  new Message(
    'building_list_load_errror',
    'Erro ao carregar a lista de edifícios'
  ),
  new Message('show_all', 'Ver tudo'),
  new Message('profile_email_required', 'O email deve ser válido'),
  new Message('profile_building_selected', 'O edifício é obrigatório'),
  new Message('profile_password_required', 'A palavra passe é obrigatória'),
  new Message(
    'profile_newpassword_required',
    'A nova palavra passe é obrigatória'
  ),
  new Message(
    'profile_newpasswordrepeat_required',
    'Deve repetir a nova palavra passe'
  ),
  new Message(
    'profile_newpasswordrepeat_notSame',
    'As palavras passe não correspondem'
  ),
  new Message('getBuildings_error', 'Erro ao obter edifícios'),

  new Message('updateProfile_error', 'Erro ao atualizar perfil'),
  new Message('updateProfile_success', 'Perfil atualizado com sucesso'),
  new Message('updatePassword_success', 'Palavr passe atualizada corretamente'),
  new Message(
    'updatePassword_error',
    'Ocorreu um erro ao alterar a plavra passe'
  ),
  new Message('user_image', 'Imagem'),
  new Message('user_image_type_error', 'O arquivo deve ser uma imagem'),
  new Message(
    'user_emailOrName_empty',
    'Qualquer um dos seguintes campos está vazio: email, nome. Se o usuário não tiver um email, o email não será enviado ao fazer uma reserva'
  ),
  new Message('reservation_list_filter_msg', 'Filtrado por {0}'),
  new Message('login_username', 'Utilizador'),
  new Message('login_password', 'Palavra passe'),
  new Message('reset_password', 'Palavra passe'),
  new Message('reset_newpassword', 'Nova Palavra passe'),
  new Message('login_username_err', 'O campo do utilizador é obrigatório'),
  new Message('login_password_err', 'O campo da palavra passe é obrigatório'),
  new Message(
    'reset_password_msj',
    'Para solicitar uma nova palavra passe, digite'
  ),
  new Message('reset_password_err', 'Deve inserir um email válido'),
  new Message('error_login', 'Erro no login'),
  new Message(
    'error_reset',
    'Ocorreu um erro ao enviar o email. Entre em contato com o administrador.'
  ),
  new Message('dowloads_android_title', 'App Vodafone Plaza para Android'),
  new Message(
    'dowloads_android_desc',
    'deve autorizar a instalação em "Configurações", "Configurações avançadas", "Segurança" e ativar "Aplicativos de origem desconhecida"'
  ),
  new Message('dowloads_btn', 'Descarregar'),
  new Message('dowloads_ios_title', 'App Vodafone Plaza para iOS'),
  new Message(
    'dowloads_ios_desc',
    'Receberá um aviso de que o aplicativo é de um desenvolvedor não identificado; você deve aceitar a instalação de qualquer maneira (Confie no provedor de serviços)'
  ),
  new Message('dowloads_android_tower_title', 'App Tower para Android'),
  new Message('dowloads_ios_tower_title', 'App Tower para iOS'),
  new Message('modal_edit_reservation', 'Editar reserva'),
  new Message('modal_save_btn', 'Salvar'),
  new Message('building_select_lbl', 'Edifícios'),
  new Message('room_select_lbl', 'Salas'),
  new Message('select_building', 'Seleccione edifício'),
  new Message('select_room', 'Seleccione sala'),
  new Message('reserve_room', 'Reservar sala'),
  new Message('room_detail_error', 'Erro ao obter detalhes da sala'),
  new Message(
    'avaiableRooms_empty',
    'Não há salas disponíveis para a data selecionada'
  ),
  new Message('room_not_available', 'A sala não está disponível'),
  new Message('load_rooms_error', 'Erro ao carregar salas'),
  new Message('duration', 'duração'),
  new Message('room_detail_name_title', 'Sala'),
  new Message('headquarters_list', 'Sede'),
  new Message('headquarters', 'Campus'),
  new Message('getHeadquarters_error', 'Erro ao obter sede'),
  new Message(
    'userWithoutHeadquartersMustSelectOne',
    'Por favor, selecione uma sede para começar a usar a aplicaçao'
  ),
  new Message('login_access_btn', 'Login'),
  new Message('login_forgot_password', 'Esqueci minha palavra passe'),
  new Message(
    'login_problem_mail',
    'Se você tiver problemas para acessar o serviço, entre em contato conosco a partir '
  ),
  new Message('login_problem_here', 'daqui'),
  new Message(
    'resetPassword_message_ok',
    'Um email com uma nova palavra passe foi enviado a você.'
  ),
  new Message(
    'resetPassword_message_error',
    'Erro ao atualizar a senha'
  ),
  new Message('resetPassword_request_btn', 'Pedido'),
  new Message('resetPassword_return_btn', 'Volte'),
  new Message('parking_nextDraw_message', 'A próxima tarefa será'),
  new Message('parking_yieldToday_message', 'Ceda hoje'),
  new Message('parking_yield_message', 'Dar lugar'),
  new Message('parking_next_raffle', 'O próximo sorteio será no'),
  new Message('parking_notVehicle', 'Para registrar seu veículo, você pode fazê-lo em Seus veículos dentro de seu perfil.'),
  new Message('parking_raffle_info', 'Hoje você não tem estacionamento'),
  new Message('parking_raffle_info_not_available', 'Sorteio não disponível'),
  new Message('parking_enter_raffle', 'Digite o sorteio'),
  new Message(
    'parking_yieldTemporary_message',
    'Use a opção "Transferência temporária" para períodos de férias ou ausências. Você pode determinar até 3 períodos nos quais deseja transferir seu local.'
  ),
  new Message(
    'parkingOne_yieldTemporary_message',
    'Ceda agora para deixar seu lugar livre até o próximo sorteio. Use a opção "Transferência temporária" para períodos de férias ou ausências. '
  ),
  new Message(
    'parkingOne_checkedRaffle_title',
    'Você está participando do sorteio'
  ),
  new Message(
    'parkingOne_checkedRaffle_description',
    'Enquanto você mantiver essa opção ativa, todos os dias você entrará na loteria para vagas de estacionamento. Lembre-se de desativá-lo durante férias ou ausências. '
  ),
  new Message(
    'parkingOne_no_checkedRaffle_title',
    'Você não está participando do sorteio'
  ),
  new Message(
    'parkingOne_checkedRaffle_description_second',
    'Se você quiser saber como a loteria funciona, pressione'
  ),
  new Message(
    'parkingOne_raffle_end',
    'Erro, a oferta foi excluída ou desativada'
  ),
  new Message(
    'parking_without_assigned_parkingSpot',
    'Você não tem um lugar de estacionamento atribuído hoje'
  ),
  new Message(
    'parking_with_assigned_parkingSpot_today',
    'Seu lugar de estacionamento para hoje '
  ),
  new Message(
    'parking_yielded_parkingSpot',
    'Seu lugar de estacionamento é transferido até '
  ),
  new Message('parking_yielded_parkingRecover', 'Você vai recuperar seu lugar em '),
  new Message('parking_yielded_parkingConfirm', 'Você cancelou a transferência do seu lugar'),
  new Message('parking_assigned_parkingSpot', 'Você tem um lugar até '),
  new Message('parking_assigned_fixed_parkingSpot', 'Você tem um local fixo atribuído '),
  new Message('parking_assigned_temporary_parkingSpot', 'Você tem um local temporário atribuído até '),
  new Message('parking_request_seeDetails_btn', 'Ver detalhes'),
  new Message('parking_request_btn', 'Solicitar um lugar de estacionamento'),
  new Message(
    'parking_parkingSpot_absence',
    'Você pode transferir seu lugar de estacionamento em caso de ausência ou férias'
  ),
  new Message('parking_user_message', 'Lugar atribuído até o próximo sorteio'),
  new Message('parking_temporal_message', 'PLugar cedido temporariamente'),
  new Message(
    'parking_request_periods',
    'Para solicitar um lugar de estacionamento, selecione até 3 períodos'
  ),
  new Message('parking_yield_temporary', 'Transferência temporária'),
  new Message('parking_from_date', 'De'),
  new Message('parking_to_date', 'Até'),
  new Message('parking_disabled_reservation_title', 'Estacionamento liberado'),
  new Message('parking_disabled_reservation_description', 'Lançamos o estacionamento durante a crise do COVID-19'),

  new Message('send_invite_btn', 'Convidar'),
  new Message('send_invitation', 'Enviar convite'),
  new Message('user_to_send_invite', 'Pesquisar usuários…'),
  new Message('search_user', 'Pessoa de busca'),
  new Message(
    'search_users_to_send_invitation',
    'Escolha os usuários para os quais você deseja enviar o convite:'
  ),
  new Message('invitation_user_not_found', 'Nenhum usuário encontrado'),
  new Message('invitation_sent', 'O convite foi enviado com sucesso'),
  new Message(
    'invitations_users_search_error',
    'Ocorreu um problema ao procurar usuários, tente novamente.'
  ),
  new Message(
    'invitations_users_department_search_error',
    'Ocorreu um problema ao pesquisar por usuário ou departamento, tente novamente'
  ),
  new Message(
    'invitations_users_send_error',
    'Ocorreu um problema ao enviar o convite, tente novamente.'
  ),
  new Message('invitations_user_not_registered', 'Usuário não registrado'),
  new Message('reservation_pending_confirmation', 'Pendente de confirmação'),
  new Message(
    'error_validate_token',
    'Ocorreu um erro ao validar seu usuário. Você pode fazer login manualmente no seguinte link.'
  ),
  new Message(
    'room_reserve_byRoomAndPeriod_btn',
    'Pesquisa por quarto e período'
  ),
  new Message(
    'search_reservations_periods_by_room',
    'Encontre horários para um quarto'
  ),
  new Message('hours', 'Horas'),
  new Message('time_str_from', 'Desde as'),
  new Message('time_str_to', 'Até las'),
  new Message('floor', 'Plantar'),
  new Message('select_floor', 'Selecionar planta'),
  new Message('area', 'Espaço'),
  new Message('select_area', 'Selecionar espaço'),
  new Message('resource', 'Recurso'),
  new Message('select_resource', 'Selecionar recurso'),
  new Message('categorie', 'Categoria'),
  new Message('select_categorie', 'Selecione a categoria'),
  new Message('observation', 'Observações'),
  new Message('send_report', 'Enviar relatório'),
  new Message('title_incidence', 'Relatar incidente'),
  new Message('box_title', 'Selecione uma posição para reservar'),
  new Message('box_title_legend', 'Lenda'),
  new Message('box_free', 'Livre'),
  new Message('box_partially_occupied', 'Parcialmente ocupado'),
  new Message('box_occupied', 'Ocupado'),
  new Message('box_disabled', 'Desativado'),
  new Message('box_reserved', 'Reservado'),
  new Message('box_yourDesk', 'Sua mesa'),
  new Message('box_userSelectDesk', 'Posição do usuário selecionada'),
  new Message('box_position_available', 'Posições selecionadas'),
  new Message('reserveDesk_title', 'Reservar trabalho'),
  new Message('reserveDeskDinning_title', 'Reservar jantar'),
  new Message('reserveDesk_busy', 'Ocupado'),
  new Message('reserveDesk_toSelect', 'SELECCIONAR'),
  new Message('reserveDesk_selected', 'SELECCIONADO'),
  new Message('reserveDesk_cancel', 'CANCELAR'),
  new Message('reserveDesk_reserve', 'RESERVA'),
  new Message('reserveDesk_reserve_all', 'JORNADA COMPLETA'),
  new Message('reserveDesk_edit', 'EDITAR'),

  new Message('message_DeskNotSelected_Title', 'Não pode ser selecionado'),
  new Message('message_DeskConsecutive_Description', 'Apenas slots de tempo consecutivos podem ser reservados.'),
  new Message('message_DeskNot_Description', 'Não é possível selecionar mais intervalos de tempo.'),

  new Message('message_DeskNotSelected_Title--NotSelect', 'Não pode ser reservado'),
  new Message('message_DeskConsecutive_Description--NotSelect', 'Para fazer a reserva você deve selecionar pelo menos um horário.'),

  // Security
  new Message('security_search_title', 'Procurar'),
  new Message('security_search_select', 'Selecione uma opção'),
  new Message('security_search_name', 'Nome'),
  new Message('security_search_email', 'Endereço de correio'),
  new Message('security_search_code', 'Código local'),
  new Message('security_search_model', 'Modelo'),
  new Message('security_search_plate', 'Inscrição'),
  new Message('security_search_place', 'Número do local'),
  new Message('security_search_building', 'Construção'),
  new Message('security_search_floor', 'Chão'),
  new Message('security_search_btn', 'Olhe para'),
  new Message('security_search_value', 'Texto'),
  new Message('security_search_value_error', 'digite pelo menos 3 caracteres'),
  new Message('security_search_by_name', 'Não há resultados para esse nome'),
  new Message('security_search_by_plate', 'Não há resultados para essa inscrição'),
  new Message('security_search_by_place', 'Não há resultados para essa posição'),
  new Message('security_search_service_error', 'Ups! Ocorreu um erro inesperado'),
  new Message('security_search_results', 'Resultados'),

  // Reservation by position
  new Message('reservation_by_position_title', 'Reservar uma posição'),
  new Message('reservation_by_position_btn', 'Reserva'),
  new Message('reserved_seats', 'Assentos reservados'),
  new Message('no_reserved_seats', 'Você não tem posições reservadas'),
  new Message('reservation_recover_desk_error', 'Erro ao recuperar assentos reservados'),
  new Message('reservation_desk_success', 'Seu assento foi reservado com sucesso.'),
  new Message('reservation_desk_error', 'Ocorreu um erro durante a reserva.'),
  new Message('reservation_checkin_success', 'Check-in realizado com sucesso.'),
  new Message('reservation_checkin_error', 'Não foi possível fazer o check-in.'),
  new Message('reservation_checkout_success', 'Check-out realizado com sucesso.'),
  new Message('reservation_checkout_error', 'Não foi possível fazer o check-out.'),
  new Message('reservation_not_available', 'Não foi possível reservar. Você já tem uma reserva de assento na mesma data.'),
  new Message('reservation_give', 'Cancelar'),
  new Message('reservation_edit', 'Editar'),
  new Message('reservation_checkin', 'Check-In'),
  new Message('reservation_checkout', 'Check-Out'),
  new Message('reservation_edit_success', 'Sua reserva foi modificada com sucesso.'),
  new Message('reservation_edit_error', 'Ocorreu um erro durante a modificação.'),
  new Message('reservation_cancel_success', 'Sua reserva foi cancelada com sucesso.'),
  new Message('reservation_cancel_error', 'Ocorreu um erro durante o processo.'),
  new Message('reservation_historical_title', 'Histórico'),
  new Message('reservation_historical_description', 'Você ainda não finalizou as reservas'),
  new Message('reservation_permissions', 'Você não pode fazer mais reservas neste período.'),
  new Message('reservation_load_more', 'Carregue mais'),
  new Message('reservation_share', 'Compartilhar'),
  new Message('reservation_add_people', 'Adicionar pessoas'),
  new Message('reservation_by', 'por '),
  new Message('reservation_busy_by', 'OCUPADO POR'),
  new Message('reservation_by_admin', 'Reservado por '),
  new Message('reservation_menu_user', 'Pesquisar posição do usuário'),
  new Message('reservation_menu_multiple', 'Reservas múltiplas'),
  new Message('reservation_menu_position', 'Posição de reserva'),
  new Message('reservation_user_position_date', 'Data'),
  new Message('reservation_user_position_see_reservations', 'Ver reservas'),
  new Message('reservation_multiple_tab1', 'Datos'),
  new Message('reservation_multiple_tab2', 'Pessoas'),
  new Message('reservation_multiple_number_of_positions', 'Posição nº'),
  new Message('reservation_multiple_number_of_hours', 'Horas'),
  new Message('reservation_multiple_number_of_minutes', 'Minutos'),
  new Message('reservation_multiple_start_date', 'Data de início'),
  new Message('reservation_multiple_end_date', 'Data final'),
  new Message('reservation_multiple_see_positions', 'Veja as vagas disponíveis'),
  new Message('reservation_share_success', 'Sua posição foi compartilhada com sucesso'),
  new Message('reservation_share_error', 'Erro, não foi possível compartilhar sua postagem'),
  new Message('reservation_no_available_positions', 'Não há vagas disponíveis para essa seleção'),
  new Message('reservation_available_positions_error', 'Erro ao obter assentos disponíveis'),
  new Message('reservation_multiple_desk_success', 'Sua reserva múltipla foi bem-sucedida'),
  new Message('reservation_search_user', 'Pessoa'),

  // Incidences
  new Message('incidence_file_type', 'Erro. O arquivo selecionado não é uma imagem'),
  new Message('user_image_optional', '(Opcional)'),
  new Message('no_incidences', 'Nenhum incidente foi criado'),
  new Message('user_image_select_file', 'Selecione o arquivo'),
  new Message('user_image_no_file', 'Nenhum arquivo selecionado'),
  new Message('user_image_file_charge', 'Clique para carregar o arquivo'),

  // Azure
  new Message('login_with_azure', 'Entrar como SSO'),

  // Profile
  new Message('change_password_btn', 'Mudar senha'),
  new Message('my_vehicles_btn', 'Meus veículos'),
  new Message('update_password_ok', 'Senha atualizada corretamente'),
  new Message('update_password_fail', 'Erro, a senha introduzida não corresponde à sua senha'),
  new Message('update_password_error', 'Erro, não se ha podido atualizar la contraseña'),
  new Message('profile_vehicle_main', 'Diretor'),
  new Message('profile_vehicle_edit', 'Editar'),
  new Message('profile_vehicle_remove', 'Remover'),
  new Message('profile_principal_vehicle', 'Veículo primário corretamente atribuído'),
  new Message('profile_edit_vehicle', 'Veículo editado corretamente'),
  new Message('profile_edit_vehicle_error', 'Você deve selecionar outro veículo como principal anteriormente'),
  new Message('profile_vehicleEdit_type_title', 'TIPO DE VEÍCULO'),
  new Message('profile_vehicleEdit_type_select', 'Selecione o tipo'),
  new Message('profile_vehicleEdit_license_title', 'PLACA DO CARRO'),
  new Message('profile_vehicleEdit_license_placeholder', 'Insira a placa do carro'),
  new Message('profile_vehicleEdit_model_title', 'MODELO'),
  new Message('profile_vehicleEdit_model_placeholder', 'Apresentar o modelo'),
  new Message('profile_vehicleEdit_color_title', 'COR'),
  new Message('profile_vehicleEdit_color_placeholder', 'Insira a cor'),
  new Message('profile_vehicleEdit_main_title', 'VEÍCULO PRINCIPAL'),
  new Message('profile_vehicleEdit_add', 'Adicionar veículo'),
  new Message('profile_vehicleEdit_edit', 'Editar veículo'),
  new Message('profile_vehicleEdit_cancel', 'Cancelar'),
  new Message('profile_department', 'Departamento'),
  new Message('profile_my_reservations', 'Tornar minhas reservas públicas'),
  new Message('profile_departments_error', 'Falha ao obter departamentos de sua sede'),
  new Message('profile_assistance_parking', 'Solicitar vaga de estacionamento'),
  new Message('profile_assistance_parking_description', 'Reserve automaticamente uma vaga de estacionamento ao relatar a presença no escritório'),
  new Message('profile_assistance_no_vehicle_for_parking', 'Você deve registrar um veículo para solicitar uma vaga de estacionamento'),
  new Message('profile_assistance_dinner', 'Solicite uma mudança na sala de jantar'),
  new Message('profile_assistance_dinner_description', 'Reserve automaticamente um horário no restaurante ao relatar a presença no escritório. Se não houver horários disponíveis, selecione um novo'),
  new Message('support_preferences_btn', 'Preferências de suporte'),
  new Message('profile_form_update_error', 'Falha na atualização do suporte'),
  new Message('profile_form_assistance_config', 'Configurações de atendimento'),
  new Message('profile_form_vehicle', 'Seus veículos'),
  new Message('profile_form_pass_mod', 'Modificar senha'),
  new Message('profile_form_vehicle_add', 'Veículo adicionado com sucesso'),
  new Message('profile_form_vehicle_delete', 'Veículo removido com sucesso'),
  new Message('profile_form_vehicle_delete_error', 'Falha ao remover veículo'),
  new Message('profile_error_image', 'Erro. O tamanho é maior que 5 MB'),
  new Message('profile_preferred_time_slot', 'PERÍODO DE TEMPO PREFERIDO'),

  // MODAL INDICATE YOUR DEPT
  new Message('indicate_your_dept_title', 'Indique seu departamento'),
  new Message('indicate_your_dept_desc', 
  'Para utilizar o aplicativo, o usuário deve selecionar o departamento ao qual pertence.'),
  new Message('indicate_your_dept_bt_cancel', 'CANCELAR'),
  new Message('indicate_your_dept_bt', 'ACEITAR'),
  new Message('indicate_your_dept_error', 'Você deve selecionar um departamento'),

  // ASSISTANCE
  new Message('assistance_bottom_previous', 'Anterior'),
  new Message('assistance_bottom_next', 'Próximo'),
  new Message('assistance_bottom_save', 'SALVE'),
  new Message('assistance_bottom_solict', 'SOLICITAR'),
  new Message('assistance_bottom_cancel', 'CANCELAR'),
  new Message('assistance_bottom_cancel_assistance', 'Cancelar comparecimento'),
  new Message('assistance_text_confirm', 'Assistência confirmada'),
  new Message('assistance_text_confirmed', 'Participantes confirmados'),
  new Message('assistance_text_not_confirm', 'Confirmar assistência'),
  new Message('assistance_text_not_assign_parking_space', 'Não foi possível atribuir uma vaga de estacionamento para este dia'),
  new Message('assistance_text_assign_parking_space', 'Você atribuiu uma vaga de estacionamento'),
  new Message('assistance_text_not_assign_dinner_space', 'Não há turno livre na sala de jantar no horário selecionado'),
  new Message('assistance_text_assign_dinner_space', 'Turno da sala de jantar reservado às '),
  new Message('assistance_text_not_request_dinner_space', 'Você não solicitou um turno de sala de jantar'),
  new Message('assistance_text_not_assign_parking', 'Você não tem uma vaga de estacionamento atribuída'),
  new Message('assistance_text_not_assign_parking_text_alert', 'Você tem que registrar um veículo em seu perfil'),
  new Message('assistance_text_not_assign_vehicle_parking', 'você não tem um lugar para {0}'),
  new Message('assistance_text_not_vehicle_assign', 'Você não tem um veículo cadastrado em seu perfil'),
  new Message('assistance_text_not_request_parking', 'Você não solicitou uma vaga de estacionamento'),
  new Message('assistance_text_assign_parking', 'Você tem uma vaga de {0} reservada'),
  new Message('assistance_text_not_assign_dinner', 'Você não tem um turno reservado na sala de jantar'),
  new Message('assistance_text_assign_dinner', 'Você tem um turno reservado na sala de jantar às '),
  new Message('assistance_text_cancel_ok', 'Assistência cancelada com sucesso'),
  new Message('assistance_text_cancel_ko', 'Falha ao cancelar o assistência'),
  new Message('assistance_text_confirm_ok', 'Assistência confirmada com sucesso'),
  new Message('assistance_update_error', 'Falha ao confirmar presença'),
  new Message('assistance_text_liberate_parking_ok', 'Vaga de estacionamento liberada corretamente'),
  new Message('assistance_text_liberate_parking_ko', 'Erro ao liberar a vaga de estacionamento'),
  new Message('assistance_text_solict_parking_ok', 'Posição corretamente atribuída'),
  new Message('assistance_text_solict_parking_ko', 'Erro de atribuição de Posição'),
  new Message('assistance_text_solict_dinner_ok', 'Posição de jantar atribuída corretamente'),
  new Message('assistance_text_solict_dinner_ko', 'Erro de atribuição de local de jantar'),
  new Message('assistance_text_cancel_dinner_ok', 'Posição de jantar cancelada com sucesso'),
  new Message('assistance_text_cancel_dinner_ko', 'Erro no cancelamento do refeitório'),
  new Message('assistance_text_check_daily_menu', 'VERIFICAR O MENU DO DIA'),
  new Message('assistance_text_available_shifts', 'TURNOS DISPONÍVEIS'),
  new Message('assistance_text_legend', 'Lenda'),
  new Message('assistance_text_reserve_error', 'Você excedeu o número máximo de reservas'),
  new Message('assistance_text_turn_selection_error', 'Você deve escolher uma curva'),
  new Message('assistance_text_not_dining_room_service', 'Não há serviço de refeitório para este dia'),
  new Message('assistance_text_legend_alert', 'Capacidade excedida. Revise seu planejamento de presença no escritório'),

  // DINNING OCCUPATION
  new Message('occupation_text_free', 'Livre'),
  new Message('occupation_text_occupied', 'Ocupado'),
  new Message('occupation_text_occupancy_level', 'Nível de ocupação em tempo real'),
  new Message('occupation_text_last_update', 'Última atualização às '),

  // PARKING OCCUPATION
  new Message('parking_occupation_title', 'Estacionamento'),
  new Message('parking_occupation_free_places', 'Lugares livres'),
  new Message('parking_occupation_building_a', 'EDIFÍCIO A'),
  new Message('parking_occupation_building_b', 'EDIFÍCIO B'),
  new Message('parking_occupation_schedule', 'Perfil de agenda de ocupação'),
  new Message('parking_occupation_normal_parking', 'Estacionamento normal'),
  new Message('parking_occupation_electric_parking', 'Estacionamento elétrico'),
  new Message('parking_occupation_external_error', 'Falha do servidor Obix ao obter dados de ocupação'),

  // CANTINE
  new Message('cantine_text_error_data', 'Falha ao obter dados')
];
