import { Directive, TemplateRef, ViewContainerRef, Input, ElementRef, Renderer } from '@angular/core';

@Directive({
  selector: '[appAddTable]'
})
export class AddTableDirective {

  constructor(private el:ElementRef, private renderer:Renderer) { }

  @Input() set appAddTable(styleObject: any){
    this.renderer.setElementStyle(this.el.nativeElement, 'top', styleObject.cordy.toString())
    this.renderer.setElementStyle(this.el.nativeElement, 'left', styleObject.cordx.toString())
    this.renderer.setElementStyle(this.el.nativeElement, 'width', styleObject.width.toString())
    this.renderer.setElementStyle(this.el.nativeElement, 'height', styleObject.height.toString())
  }


}
