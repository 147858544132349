import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthenticationService } from './shared/services/authentication.service';
import globals = require('./globals');

@Component({
  selector: 'app-main',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (
          !this.authenticationService.existLogedUser() &&
          event.url !== '/login' &&
          !event.url.includes('/validate-token')
        ) {
          if (event.url.includes('/validate-token')) {
            globals.redirectPage = event.url;
            this.router.navigate([event.url]);
          } else if (
            !event.url.includes('/boxOffice') &&
            !event.url.includes('/boxOffice-dinner') &&
            !event.url.includes('/readerBoxOffice') &&
            !event.url.includes('/readerBoxOffice-dinner') &&
            !event.url.includes('/reservationDesk') &&
            !event.url.includes('/reservationDesk-dinner') &&
            !event.url.includes('/viewMultipleBoxOffice') &&
            !event.url.includes('/viewMultipleBoxOffice-dinner') &&
            !event.url.includes('/viewSelectBoxOffice') &&
            !event.url.includes('/viewSelectBoxOffice-dinner') &&
            !event.url.includes('/reservationDeskEdit') &&
            !event.url.includes('/reservationDeskEdit-dinner') &&
            !event.url.includes('/resetPassword') &&
            !event.url.includes('/app-version') &&
            !event.url.includes('/dinning-occupation') &&
            !event.url.includes('/dinning-occupation-headquarter') &&
            !event.url.includes('/parking-occupation')
          ) {
            if (event.url !== '/') {
              globals.redirectPage = event.url;
            }
            this.router.navigate(['/login']);
          }
        }

        if (
          event.url.includes('/boxOffice') ||
          event.url.includes('/boxOffice-dinner') ||
          event.url.includes('/readerBoxOffice') ||
          event.url.includes('/readerBoxOffice-dinner') ||
          event.url.includes('/reservationDesk') ||
          event.url.includes('/reservationDesk-dinner') ||
          event.url.includes('/viewMultipleBoxOffice') ||
          event.url.includes('/viewMultipleBoxOffice-dinner') ||
          event.url.includes('/viewSelectBoxOffice') ||
          event.url.includes('/viewSelectBoxOffice-dinner') ||
          event.url.includes('/reservationDeskEdit') ||
          event.url.includes('/reservationDeskEdit-dinner')
        ) {
          document.getElementById('container-page').style.animation = 'none';
        }
      }
    });
  }
}
