import { Authorizations } from './authorizations';
import { Building } from './building';
import { Department } from './department';
import { Groups } from './groups';
import { Headquarters } from './headquarters';

export class UserLdapWs {
  username: string;
  name: string;
  building: Building;
  headquarters: Headquarters;
  department: Department;
  showHotdeskReservation: boolean;
  maxDays: number;
  email: string;
  image: string;
  openingTime: number;
  groups: Groups;
  authorizations: Array<Authorizations>;
  accessToken: string;
  refreshToken: string;
  sessionId: string;
  expireIn: number;
  dinnerShiftFrom: Date;
  dinnerShiftTo: Date;
  dinnerShiftRequest: boolean;
  parkingRequest: boolean;
  isManager: number;
  hasVehicle: boolean;
}
