import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { BaseService } from './base.service';
import { UserLdapWs } from 'src/app/core/models/user.ldap.ws';

@Injectable({
  providedIn: 'root',
})
export class AttendanceService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getAttendanceInfo(id: number): Observable<any[]> {
    const url = `${SERVICE_BASE_URL}/api/attendance/${id}?access_token=${this.authenticationService.getToken()}`;

    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getAttendances(): Observable<any[]> {
    const url = `${SERVICE_BASE_URL}/api/attendance/list?access_token=${this.authenticationService.getToken()}`;

    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getListAttendancesUserManager(): Observable<any[]> {
    const url = `${SERVICE_BASE_URL}/api/attendance/list/users/responsable?access_token=${this.authenticationService.getToken()}`;

    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getListAttendancesUserManagerForDay(day: string): Observable<UserLdapWs[]> {
    const bodyString = JSON.stringify({ day });

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/list/users/responsable/day?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as UserLdapWs[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  cancelAssistance(idAttendance: any): Observable<any> {
    const bodyString = JSON.stringify({ idAttendance });

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/cancel?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getCandidates(days: any): Observable<any[]> {
    const bodyString = JSON.stringify(days);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/getCandidates?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  update(attendances: any): Observable<any> {
    const bodyString = JSON.stringify(attendances);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/update?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  liberateParking(id: number): Observable<any> {
    const bodyString = JSON.stringify({});

    return this.http
      .delete(
        `${SERVICE_BASE_URL}/api/attendance/parking/${id}?access_token=${this.authenticationService.getToken()}`,
        bodyString
      )
      .map((response: Response) => {
        return response.ok;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  solictParking(solict: any): Observable<any[]> {
    const bodyString = JSON.stringify(solict);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/parking?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  cancelDinnerDesk(id: number): Observable<any> {
    const bodyString = JSON.stringify({});

    return this.http
      .delete(
        `${SERVICE_BASE_URL}/api/attendance/deleteDinnerDeskReservation/${id}?access_token=${this.authenticationService.getToken()}`,
        bodyString
      )
      .map((response: Response) => {
        return response.ok;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  requestDinnerDesk(request: any): Observable<any[]> {
    const bodyString = JSON.stringify(request);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/requestDinnerDesk?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
