export const AVAILABLE_HOURS = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

export const AVAILABLE_MINUTES = ['00', '15', '30', '45'];
export const AVAILABLE_MINUTES_SPEED = ['00', '10', '20', '30', '40', '50'];
export const AVAILABLE_DURATION = ['10', '20', '30'];

export const HORAS_ROOM_DETAIL = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];

export const HOUR_MULTIPLE_RESERVATION_DINNER = [
  15,
  30,
  45,
  60
];

export const MONTHS_ASSISTANCE_ES: any[] = [
  { value: 1, desc: 'ENERO' },
  { value: 2, desc: 'FEBRERO' },
  { value: 3, desc: 'MARZO' },
  { value: 4, desc: 'ABRIL' },
  { value: 5, desc: 'MAYO' },
  { value: 6, desc: 'JUNIO' },
  { value: 7, desc: 'JULIO' },
  { value: 8, desc: 'AGOSTO' },
  { value: 9, desc: 'SEPTIEMBRE' },
  { value: 10, desc: 'OCTUBRE' },
  { value: 11, desc: 'NOVIEMBRE' },
  { value: 12, desc: 'DICIEMBRE' },
];

export const MONTHS_ASSISTANCE_EN: any[] = [
  { value: 1, desc: 'JANUARY' },
  { value: 2, desc: 'FEBRUARY' },
  { value: 3, desc: 'MARCH' },
  { value: 4, desc: 'APRIL' },
  { value: 5, desc: 'MAY' },
  { value: 6, desc: 'JUNE' },
  { value: 7, desc: 'JULY' },
  { value: 8, desc: 'AUGUST' },
  { value: 9, desc: 'SEPTEMBER' },
  { value: 10, desc: 'OCTOBER' },
  { value: 11, desc: 'NOVEMBER' },
  { value: 12, desc: 'DECEMBER' },
];

export const MONTHS_ASSISTANCE_PT: any[] = [
  { value: 1, desc: 'JANEIRO' },
  { value: 2, desc: 'FEVEREIRO' },
  { value: 3, desc: 'MARÇO' },
  { value: 4, desc: 'ABRIL' },
  { value: 5, desc: 'MAIO' },
  { value: 6, desc: 'JUNHO' },
  { value: 7, desc: 'JULHO' },
  { value: 8, desc: 'AGOSTO' },
  { value: 9, desc: 'SETEMBRO' },
  { value: 10, desc: 'OUTUBRO' },
  { value: 11, desc: 'NOVEMBRO' },
  { value: 12, desc: 'DEZEMBRO' },
];

export const ANYOS_ASSISTANCE: string[] = [
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
  '2029',
  '2030',
];

export const ES_DAYS_WEEK: string[] = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
export const EN_DAYS_WEEK: string[] = ['M', 'T', 'W', 'TH', 'F', 'S', 'SU'];
export const PT_DAYS_WEEK: string[] = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'];

export const RESERV_REFRESH_TIME = 120000;
export const LOGOUT_TIMEOUT = 3000;
export const COLLAPSED_RESOLUTION = 1230;

export const ANIMATE_CLASS_ACTIVE = 'active';
export const ANIMATE_CLASS_INACTIVE = 'inactive';
export const ANIMATE_CLASS_ACTIVE_OPACITY = 'active_opacity';
export const ANIMATE_CLASS_INACTIVE_OPACITY = 'inactive_opacity';

export const PARKING_TYPE_FIJA = 'Fija';
export const PARKING_TYPE_TEMPORAL = 'Temporal';
export const PARKING_TYPE_SIN_ASIGNAR = 'Sin_Asignar';
export const PARKING_TYPE_ROTACION = 'Rotacion';
export const RESERVATION_STATE_CHECKED = 4;
export const EXPRESS_SHORT = 30;
export const EXPRESS_LONG = 60;
export const RESERVATION_TYPE_NONE = -1;
export const RESERVATION_STATUS = {
  PENDING_CONFIRMATION: 7,
};
