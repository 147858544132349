import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { AREA_DATA_API_PATH, AREA_DINNER_DATA_API_PATH, AREA_HOTDESK_DATA_API_PATH, SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AreaService {

  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) { }

  getAreaListAll(id: String):Observable<any>{
    const url = `${SERVICE_BASE_URL}${AREA_DATA_API_PATH}${id}?access_token=${this.authenticationService.getToken()}`
    return this.http.get( url, OPTIONS).map((res:any) => {
      return res.json();
    }).catch(error => 
      this.baseService.handleError(this.authenticationService, error)
    )
  }

  getAreaListAllOption(id: String, option: string):Observable<any>{
    const selectedOption = option == 'dinner' ? AREA_DINNER_DATA_API_PATH : AREA_HOTDESK_DATA_API_PATH
    const url = `${SERVICE_BASE_URL}${selectedOption}${id}?access_token=${this.authenticationService.getToken()}`
    return this.http.get( url, OPTIONS).map((res:any) => {
      return res.json();
    }).catch(error => 
      this.baseService.handleError(this.authenticationService, error)
    )
  }

  getIntervalByAreaId(areaId: any, token: string): Observable<any[]> {
    const url = `${SERVICE_BASE_URL}/api/areas/interval/${areaId}?access_token=${token}`;

    return this.http.get(url, OPTIONS).map((response) => {
      return response.json();
    }).catch(error =>
      this.baseService.handleError(this.authenticationService, error)
    );
  }
}
