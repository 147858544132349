import { Pipe, PipeTransform, LOCALE_ID } from '@angular/core';
import { MESSAGES } from '../../core/models/messages/messages';
import { DICTIONARY } from '../../core/models/messages/dictionary';
import { Message } from '../../core/models/message';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'messagesPipe'
})
export class MessagesPipe implements PipeTransform {
  messages: Message[];
  messagesDefault: Message[];

  constructor() {
    this.messagesDefault = MESSAGES;
    let messageLocales: any;
    if (this.languageEnglish()) {
      messageLocales = DICTIONARY.filter(messageLanguage => {
        return messageLanguage.locale === 'en';
      });
    } else {
      messageLocales = DICTIONARY.filter(messageLanguage => {
        return messageLanguage.locale === navigator.language;
      });
    }

    if (messageLocales && messageLocales.length > 0) {
      this.messages = messageLocales[0].messages;
    } else {
      this.messages = this.messagesDefault;
    }
  }

  transform(key: string, params?: string[]): string {
    let messageValue = this.getByKey(this.messages, key, params);

    if (messageValue.length > 0) {
      return messageValue;
    } else {
      if (this.messages != this.messagesDefault) {
        return this.getByKey(this.messagesDefault, key, params);
      } else {
        return messageValue;
      }
    }
  }
  
  // Language modification for the mobility part
  setLanguage(value: string){
    let messageLocales = DICTIONARY.filter(messageLanguage => {
      return messageLanguage.locale == value;
    });

    if (messageLocales && messageLocales.length > 0) {
      this.messages = messageLocales[0].messages;
    } else {
      this.messages = this.messagesDefault;
    }
  }

  private getByKey(
    messages: Message[],
    key: string,
    params?: string[]
  ): string {
    let messageValue;

    let messagesReturned = messages.filter(message => {
      return message.key == key;
    });

    if (messagesReturned && messagesReturned.length > 0) {
      messageValue = messagesReturned[0].value;

      if (params && params.length > 0) {
        params.forEach((param, index) => {
          if (messageValue.indexOf(`{${index}}`) >= 0) {
            messageValue = messageValue.replace(`{${index}}`, param);
          }
        });
      }

      return messageValue;
    } else {
      return '';
    }
  }

  languageEnglish(): boolean {
    return environment['languageEnglish'];
  }
}
