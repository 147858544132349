import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { AuthenticationService } from './authentication.service';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(
    private http : Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) { }

  getCategoriesListAll(id): Observable<any>{
    const url = `${SERVICE_BASE_URL}/api/categories/listAll/${id}?access_token=${this.authenticationService.getToken()}`;
    return this.http.get(url, OPTIONS).map((res:any) => {
      return res.json();
    }).catch(error => 
      this.baseService.handleError(this.authenticationService, error)
    )
  }
}
