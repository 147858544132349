import { Message } from '../message';

export const MESSAGES_es_ES_LANG = 'es-ES';
export const MESSAGES_es_ES_explore = 'es-ES_tradnl';

export const MESSAGES_ES = [
  new Message('user', 'Usuario'),
  new Message('password', 'Contraseña'),
  new Message('welcome', 'Tu espacio para gestionar tu'),
  new Message('welcome2', 'presencia, recursos y servicios'),
  new Message('menu_init', 'Inicio'),
  new Message('menu_room', 'Reserva de salas'),
  new Message('menu_profile', 'Perfil'),
  new Message('menu_security', 'Seguridad'),
  new Message('menu_job_position', 'Puestos de trabajo'),
  new Message('menu_dinning_position', 'Reserva restaurante'),
  new Message('menu_assistance', 'Asistencia'),
  new Message('menu_assistance_user', 'Asistencia usuario'),
  new Message('menu_cantine', 'Comedor'),
  new Message('menu_logout', 'Cerrar sesión'),
  new Message(
    'error_getEquipement',
    'Error al obtener el equipamiento de la sala'
  ),
  new Message('error_getAllEquipement', 'Error al obtener el equipamiento'),
  new Message('error_getRoomDetail', 'Error al obtener el detalle de la sala'),
  new Message('rooms_reserve', 'Salas reservadas'),
  new Message('rooms_disable_reservation_title', 'Salas liberadas'),
  new Message('rooms_disable_reservation_description', 'Hemos liberado las salas de reuniones durante la crisis del COVID-19'),
  new Message('rooms_reserve_empty', 'No hay salas reservadas'),
  new Message(
    'rooms_waiting_get_error',
    'Error al obtener las salas y lista de espera'
  ),
  new Message('waiting_list', 'Lista de espera'),
  new Message('express_reservation_btn', 'Reserva express {0}'),
  new Message('room_reserve_btn', 'Reservar sala'),
  new Message('room_available', 'DISPONIBLE'),
  new Message('room_available_btn', 'Reservar'),
  new Message('room_busy', 'OCUPADA'),
  new Message('room_busy_user', 'OCUPADA POR {0}'),
  new Message(
    'express_resevation_success',
    'Se le ha asignado la sala {0} para el tramo horario {1} ({2} - {3})'
  ),
  new Message('incorrect_login', 'Login incorrecto'),
  new Message('azure_token_renewal_error', 'Error al iniciar sesión en el SSO, por favor cierre el Pop-up y vuelva a intentarlo'),
  new Message('error_forbidden', 'Sesión caducada'),
  new Message('error_required', '* Campo requerido'),
  new Message('error_numeric_required', '* Campo numérico y requerido'),
  new Message('error_license_required', '* Campo matrícula y requerido'),
  new Message('btn_view_rooms', 'Ver salas disponibles'),
  new Message('error_nextBooking', 'Error al obtener la última reserva'),
  new Message('home_events_title', 'PRÓXIMOS EVENTOS'),
  new Message('cancel_reservation_btn', 'Cancelar'),
  new Message('reservation_cancelled', 'Reserva cancelada correctamente'),
  new Message('reservation_cancelled_error', 'Error al cancelar la reserva'),
  new Message(
    'confirm_dialog_err',
    'Error al mostrar la confirmación de cancelación'
  ),
  new Message(
    'reservation_delete_msg',
    '¿Seguro que desea cancelar la reserva?'
  ),
  new Message('reservation_delete_msg_title', 'Cancelación de reserva'),
  new Message('cancel_waiting_msg', '¿Seguro que desea cancelar la espera?'),
  new Message('cancel_waiting_msg_title', 'Cancelación de espera'),
  new Message('cancel_waiting_successfully', 'Espera cancelada correctamente'),
  new Message('cancel_waiting_error', 'Error al cancelar la espera'),
  new Message(
    'cancel_waiting_dialog_error',
    'Error al mostrar la confirmación de cancelación'
  ),
  new Message('express_create_error', 'Error al crear la reserva express'),
  new Message('error_dalkonParking', 'Error al obtener la plaza'),
  new Message('home_parking_title', 'PARKING'),
  new Message('parking_ceded', 'Cedida'),
  new Message('parking_resident', 'Plaza fija'),
  new Message('parking_lot', 'Plaza asignada para hoy'),
  new Message('no_parking', 'Sin plaza'),
  new Message('no_vehicle', 'Sin vehículo'),
  new Message('session_expired', 'Sesión caducada'),
  new Message('schedule', 'Horario'),
  new Message('issue', 'Motivo'),
  new Message('equipment', 'Equipamiento'),
  new Message('time_str', 'Desde {0} hasta {1}'),
  new Message('date', 'Fecha'),
  new Message('assistants', 'Asistentes'),
  new Message('from', 'Desde'),
  new Message('since', 'Hasta'),
  new Message('meeting_reason', 'Motivo de la reunión'),
  new Message(
    'date_from_greater',
    'Fecha desde debe ser mayor que la fecha actual'
  ),
  new Message('date_to_greater', 'Fecha hasta debe ser mayor que fecha desde'),
  new Message('no_available_rooms_msg', 'No hay salas disponibles'),
  new Message(
    'no_available_rooms_equipment_msg',
    'Con el equipamiento que necesita'
  ),
  new Message('rooms_without_equip', 'Salas sin equipamiento'),
  new Message(
    'available_rooms_list_error',
    'Error al obtener las salas disponibles'
  ),
  new Message(
    'room_reserved_msg',
    'Sala {0} reservada: Añadida el día {1} desde {2} hasta {3}'
  ),
  new Message('reserve_creation_error', 'Error al crear la reserva'),
  new Message(
    'room_to_waiting',
    'La reserva ha sido añadida a la lista de espera'
  ),
  new Message(
    'room_to_waiting_error',
    'Error al añadir la reserva a la lista de espera'
  ),
  new Message('waiting_msg', '{0} desde {1} hasta {2}'),
  new Message('reservation_reason_placeholder', 'Escribe un motivo'),
  new Message('edit_reservation_error', 'Error al editar la reserva'),
  new Message('reservation_in_progress', 'En curso'),
  new Message('name', 'Nombre'),
  new Message('building', 'Edificio'),
  new Message('select_building', 'Seleccione edificio'),
  new Message('no_building', 'Sin edificio'),
  new Message('select', 'Seleccione..'),
  new Message('all_dept', 'Todos los departamentos'),
  new Message('all_user_dept', 'Todos los usuarios del departamento: '),
  new Message('save_profile_btn', 'Actualizar perfil'),
  new Message('save_building_btn', 'Guardar edificio'),
  new Message('buildings', 'Edificio'),
  new Message('save_building_successfully', 'Edificio guardado correctamente'),
  new Message('save_building_error', 'Error al guardar el edificio'),
  new Message('error_getBuildings', 'Error al obtener el listado de edificios'),
  new Message('room_detail_name_title', 'Sala'),
  new Message('headquarters_list', 'Sede'),
  new Message('headquarters', 'Sede'),
  new Message('getHeadquarters_error', 'Error al obtener las sedes'),
  new Message(
    'userWithoutHeadquartersMustSelectOne',
    'Por favor, seleccione una sede para empezar a usar la aplicación'
  ),
  new Message('login_access_btn', 'Acceder'),
  new Message('login_forgot_password', 'He olvidado mi contraseña'),
  new Message(
    'login_problem_mail',
    'Si tiene problemas para acceder al servicio contacte con nosotros desde '
  ),
  new Message('login_problem_here', 'aquí'),
  new Message(
    'resetPassword_message_ok',
    'Se le ha enviado un email con una nueva contraseña.'
  ),
  new Message(
    'resetPassword_message_error',
    'Error durante la actualización de la contraseña'
  ),
  new Message('resetPassword_request_btn', 'Solicitar'),
  new Message('resetPassword_return_btn', 'Volver'),
  new Message('parking_nextDraw_message', 'La proxima asignación será el '),
  new Message('parking_yieldToday_message', 'Ceder hoy'),
  new Message('parking_yield_message', 'Ceder plaza'),
  new Message('parking_next_raffle', 'El próximo sorteo será el'),
  new Message('parking_notVehicle', 'Para dar de alta tu vehículo, puedes hacerlo en Tus vehículos dentro de Tu perfil.'),
  new Message('parking_raffle_info', 'Hoy no tienes plaza de parking'),
  new Message('parking_raffle_info_not_available', 'Sorteo no disponible'),
  new Message('parking_enter_raffle', 'Entrar al sorteo'),
  new Message(
    'parking_yieldTemporary_message',
    'Usa la opción “Ceder temporalmente” para periodos de vacaciones o ausencias. Puedes determinar hasta 3 periodos en los que deseas ceder tu plaza.'
  ),
  new Message(
    'parkingOne_checkedRaffle_title',
    'Estás participando en el sorteo'
  ),
  new Message(
    'parkingOne_checkedRaffle_description',
    'Mientras mantenga esta opción activa, todos los días entrarás en el sorteo de plazas de parking. Recuerda desactivarla en periodo de vacaciones o ausencias. '
  ),
  new Message(
    'parkingOne_no_checkedRaffle_title',
    'No estás participando en el sorteo'
  ),
  new Message(
    'parkingOne_checkedRaffle_description_second',
    'Si quieres saber cómo funciona el sorteo de plazas, pulsa'
  ),
  new Message(
    'parkingOne_raffle_end',
    'Error, el sorteo está borrado o deshabilitado'
  ),
  new Message(
    'parking_without_assigned_parkingSpot',
    'No tienes plaza asignada hoy'
  ),
  new Message(
    'parking_with_assigned_parkingSpot_today',
    'Tu plaza para hoy es '
  ),
  new Message('parking_yielded_parkingSpot', 'Tu plaza está cedida hasta el '),
  new Message('parking_yielded_parkingRecover', 'Recuperarás tu plaza el '),
  new Message('parking_yielded_parkingConfirm', 'Has cancelado la cesión de tu plaza'),
  new Message('parking_assigned_parkingSpot', 'Tienes plaza hasta '),
  new Message('parking_assigned_fixed_parkingSpot', 'Tienes plaza fija asignada '),
  new Message('parking_ceded_square', 'Plaza cedida'),
  new Message('parking_request_seeDetails_btn', 'Ver detalles'),
  new Message('parking_request_btn', 'Solicitar plaza'),
  new Message(
    'parking_parkingSpot_absence',
    'Puedes ceder tu plaza en caso de ausencia o vacaciones'
  ),
  new Message('parking_user_message', 'Plaza cedida hasta próximo sorteo'),
  new Message('parking_temporal_message', 'Plaza cedida temporalmente'),
  new Message(
    'parking_request_periods',
    'Para solicitar plaza, selecciona hasta 3 períodos'
  ),
  new Message('parking_yield_temporary', 'Ceder temporalmente'),
  new Message('parking_from_date', 'Desde'),
  new Message('parking_to_date', 'Hasta'),
  new Message('parking_disabled_reservation_title', 'Parking liberado'),
  new Message('parking_disabled_reservation_description', 'Hemos liberado el parking durante la crisis del COVID-19'),

  new Message('send_invite_btn', 'Invitar'),
  new Message('send_invitation', 'Enviar invitación'),
  new Message('user_to_send_invite', 'Buscar usuarios…'),
  new Message('search_user', 'Buscar persona'),
  new Message(
    'search_users_to_send_invitation',
    'Elige a los usuarios a los que quieres enviarles la invitación:'
  ),
  new Message('invitation_user_not_found', 'No se han encontrado usuarios'),
  new Message('invitation_sent', 'La invitación se ha enviado correctamente'),
  new Message(
    'invitations_users_search_error',
    'Ha ocurrido un problema al buscar los usuarios, por favor inténtalo de nuevo'
  ),
  new Message(
    'invitations_users_department_search_error',
    'Ha ocurrido un problema al buscar por usuario o departamento, por favor inténtalo de nuevo'
  ),
  new Message(
    'invitations_users_send_error',
    'Ha ocurrido un problema al enviar la invitación, por favor inténtalo de nuevo'
  ),
  new Message('invitations_user_not_registered', 'Usuario no registrado'),
  new Message('reservation_pending_confirmation', 'Solicitada'),
  new Message('room_reserve_byRoomAndPeriod_btn', 'Buscar por sala y periodo'),
  new Message(
    'search_reservations_periods_by_room',
    'Encontrar huecos para una sala'
  ),
  new Message('hours', 'Horas'),
  new Message('time_str_from', 'De'),
  new Message('time_str_to', 'a'),
  new Message('floor', 'Planta'),
  new Message('select_floor', 'Seleccione planta'),
  new Message('area', 'Espacio'),
  new Message('select_area', 'Seleccione espacio'),
  new Message('resource', 'Recurso'),
  new Message('select_resource', 'Seleccione recurso'),
  new Message('categorie', 'Categoría'),
  new Message('select_categorie', 'Seleccione categoría'),
  new Message('observation', 'Observaciones'),
  new Message('send_report', 'Enviar reporte'),
  new Message('title_incidence', 'Reportar incidencia'),
  new Message('box_title', 'Selecciona un puesto para reservarlo'),
  new Message('box_title_legend', 'Leyenda'),
  new Message('box_free', 'Libre'),
  new Message('box_partially_occupied', 'Parcialmente ocupado'),
  new Message('box_occupied', 'Ocupado'),
  new Message('box_disabled', 'Deshabilitado'),
  new Message('box_reserved', 'Reservado'),
  new Message('box_yourDesk', 'Tu puesto'),
  new Message('box_userSelectDesk', 'Puesto usuario seleccionado'),
  new Message('box_position_available', 'Puestos seleccionados'),
  new Message('reserveDesk_title', 'Reservar puesto de trabajo'),
  new Message('reserveDeskDinning_title', 'Reservar puesto comedor'),
  new Message('reserveDesk_busy', 'Ocupada'),
  new Message('reserveDesk_toSelect', 'SELECCIONAR'),
  new Message('reserveDesk_selected', 'SELECCIONADO'),
  new Message('reserveDesk_cancel', 'CANCELAR'),
  new Message('reserveDesk_reserve', 'RESERVAR'),
  new Message('reserveDesk_reserve_all', 'JORNADA COMPLETA'),
  new Message('reserveDesk_edit', 'EDITAR'),

  new Message('message_DeskNotSelected_Title', 'No se puede seleccionar'),
  new Message('message_DeskConsecutive_Description', 'Sólo se pueden reservar tramos horarios consecutivos.'),
  new Message('message_DeskNot_Description', 'No se pueden seleccionar más tramos horarios.'),

  new Message('message_DeskNotSelected_Title--NotSelect', 'No se puede reservar'),
  new Message('message_DeskConsecutive_Description--NotSelect', 'Para realizar la reserva debe seleccionar al menos un tramo horario.'),

  // Security
  new Message('security_search_title', 'Buscar por'),
  new Message('security_search_select', 'Seleccione una opción'),
  new Message('security_search_name', 'Nombre'),
  new Message('security_search_email', 'Dirección de correo'),
  new Message('security_search_code', 'Código de plaza'),
  new Message('security_search_model', 'Modelo'),
  new Message('security_search_plate', 'Matrícula'),
  new Message('security_search_place', 'Nº Plaza'),
  new Message('security_search_building', 'Edificio'),
  new Message('security_search_floor', 'Planta'),
  new Message('security_search_btn', 'Buscar'),
  new Message('security_search_value', 'Texto'),
  new Message('security_search_value_error', 'Por favor, introduzca al menos 3 caracteres'),
  new Message('security_search_by_name', 'No existen resultados para dicho nombre'),
  new Message('security_search_by_plate', 'No existen resultados para dicha matrícula'),
  new Message('security_search_by_place', 'No existen resultados para dicha plaza'),
  new Message('security_search_service_error', 'Oops, un error inesperado ocurrió'),
  new Message('security_search_results', 'Resultados'),

  // Reservation by position
  new Message('reservation_by_position_title', 'Reservar un puesto'),
  new Message('reservation_by_position_btn', 'Reserva'),
  new Message('reserved_seats', 'Puestos reservados'),
  new Message('no_reserved_seats', 'No tienes puestos reservados'),
  new Message('reservation_recover_desk_error', 'Error al recuperar los puestos reservados'),
  new Message('reservation_desk_success', 'Su puesto se ha reservado correctamente.'),
  new Message('reservation_desk_error', 'Se ha producido un error durante la reserva.'),
  new Message('reservation_checkin_success', 'El check-in se ha realizado correctamente.'),
  new Message('reservation_checkin_error', 'No se ha podido realizar el check-in.'),
  new Message('reservation_checkout_success', 'El check-out se ha realizado correctamente.'),
  new Message('reservation_checkout_error', 'No se ha podido realizar el check-out.'),
  new Message('reservation_not_available', 'No se pudo reservar. Ya tiene una reserva de un puesto en la misma fecha.'),
  new Message('reservation_give', 'Cancelar'),
  new Message('reservation_edit', 'Editar'),
  new Message('reservation_checkin', 'Check-In'),
  new Message('reservation_checkout', 'Check-Out'),
  new Message('reservation_edit_success', 'Se ha modificado su reserva correctamente.'),
  new Message('reservation_edit_error', 'Se ha producido un error durante la modificación.'),
  new Message('reservation_cancel_success', 'Se ha cancelado su reserva correctamente.'),
  new Message('reservation_cancel_error', 'Se ha producido un error durante el proceso.'),
  new Message('reservation_historical_title', 'Histórico'),
  new Message('reservation_historical_description', 'Aún no tienes reservas finalizadas'),
  new Message('reservation_permissions', 'No puede realizar más reservas en este periodo.'),
  new Message('reservation_load_more', 'Cargar más'),
  new Message('reservation_share', 'Compartir'),
  new Message('reservation_add_people', 'Añadir personas'),
  new Message('reservation_by', 'por '),
  new Message('reservation_busy_by', 'OCUPADA POR'),
  new Message('reservation_by_admin', 'Reservado por '),
  new Message('reservation_menu_user', 'Buscar puesto usuario'),
  new Message('reservation_menu_multiple', 'Reserva múltiple'),
  new Message('reservation_menu_position', 'Reservar puesto'),
  new Message('reservation_user_position_date', 'Fecha'),
  new Message('reservation_user_position_see_reservations', 'Ver reservas'),
  new Message('reservation_multiple_tab1', 'Datos'),
  new Message('reservation_multiple_tab2', 'Personas'),
  new Message('reservation_multiple_number_of_positions', 'Nº Puestos'),
  new Message('reservation_multiple_number_of_hours', 'Horas'),
  new Message('reservation_multiple_number_of_minutes', 'Minutos'),
  new Message('reservation_multiple_start_date', 'Fecha Inicio'),
  new Message('reservation_multiple_end_date', 'Fecha Fin'),
  new Message('reservation_multiple_see_positions', 'Ver puestos disponibles'),
  new Message('reservation_share_success', 'Se ha compartido tu puesto correctamente'),
  new Message('reservation_share_error', 'Error, no se ha podido compartir tu puesto'),
  new Message('reservation_no_available_positions', 'No hay puestos disponibles para esa selección'),
  new Message('reservation_available_positions_error', 'Error al obtener los puestos disponibles'),
  new Message('reservation_multiple_desk_success', 'Su reserva multiple se ha realizado correctamente'),
  new Message('reservation_search_user', 'Persona'),

  new Message('profile_email_required', 'El email ha de ser válido'),
  new Message('profile_building_selected', 'El edificio es obligatorio'),
  new Message('profile_password_required', 'La contraseña es obligatoria'),
  new Message(
    'profile_newpassword_required',
    'La nueva contraseña es obligatoria'
  ),
  new Message(
    'profile_newpasswordrepeat_required',
    'Debe repetir la nueva contraseña'
  ),
  new Message(
    'profile_newpasswordrepeat_notSame',
    'Las contraseñas no coinciden'
  ),
  new Message('getBuildings_error', 'Error al obtener los edificios'),
  new Message('updateProfile_error', 'Error al actualizar el perfil'),
  new Message('updateProfile_success', 'Perfil actualizado correctamente'),
  new Message('updatePassword_success', 'Contraseña actualizada correctamente'),
  new Message(
    'updatePassword_error',
    'Ha ocurrido un error al cambiar la contraseña.'
  ),

  // Incidences
  new Message('incidence_file_type', 'Error. El archivo seleccionado no es una imagen'),
  new Message('user_image', 'Imagen'),
  new Message('user_image_optional', '(Opcional)'),
  new Message('no_incidences', 'No hay incidencias creadas'),
  new Message('user_image_select_file', 'Seleccionar archivo'),
  new Message('user_image_no_file', 'Ningún fichero seleccionado'),
  new Message('user_image_file_charge', 'Haga clic para cargar el archivo'),

  // Download
  new Message('dowloads_android_title', 'App Vodafone Plaza para Android'),
  new Message(
    'dowloads_android_desc',
    'Debes autorizar la instalación en “Ajustes”, “Ajustes avanzados”, “Seguridad” y activar “Apps de origen desconocido”'
  ),
  new Message('dowloads_btn', 'Descargar'),
  new Message('dowloads_ios_title', 'App Vodafone Plaza para iOS'),
  new Message(
    'dowloads_ios_desc',
    'Recibirás un aviso de que la aplicación proviene de un desarrollador no identificado, debes aceptar la instalación de todos modos (Confiar en el proveedor de servicios)'
  ),
  new Message('dowloads_android_tower_title', 'App Tower para Android'),
  new Message('dowloads_ios_tower_title', 'App Tower para iOS'),

  // Azure
  new Message('login_with_azure', 'Iniciar sesion con SSO'),

  // Profile
  new Message('change_password_btn', 'Cambiar contraseña'),
  new Message('my_vehicles_btn', 'Mis vehículos'),
  new Message('update_password_ok', 'Contraseña actualizada correctamente'),
  new Message('update_password_fail', 'Error, la contraseña introducida no coincide con la suya'),
  new Message('update_password_error', 'Error, no se ha podido actualizar la contraseña'),
  new Message('profile_vehicle_main', 'Principal'),
  new Message('profile_vehicle_edit', 'Editar'),
  new Message('profile_vehicle_remove', 'Eliminar'),
  new Message('profile_vehicleEdit_type_title', 'TIPO DE VEHÍCULO'),
  new Message('profile_vehicleEdit_type_select', 'Selecciona el tipo'),
  new Message('profile_vehicleEdit_license_title', 'MATRICULA'),
  new Message('profile_vehicleEdit_license_placeholder', 'Introduce la matrícula'),
  new Message('profile_vehicleEdit_model_title', 'MODELO'),
  new Message('profile_vehicleEdit_model_placeholder', 'Introduce el modelo'),
  new Message('profile_vehicleEdit_color_title', 'COLOR'),
  new Message('profile_vehicleEdit_color_placeholder', 'Introduce el color'),
  new Message('profile_vehicleEdit_main_title', 'VEHICULO PRINCIPAL'),
  new Message('profile_vehicleEdit_add', 'Añadir vehículo'),
  new Message('profile_vehicleEdit_edit', 'Editar vehículo'),
  new Message('profile_vehicleEdit_cancel', 'Cancelar'),
  new Message('profile_principal_vehicle', 'Vehículo asignado como principal correctamente'),
  new Message('profile_edit_vehicle', 'Vehiculo editado correctamente'),
  new Message('profile_edit_vehicle_error', 'Debe seleccionar otro vehículo como principal previamente'),
  new Message('profile_department', 'Departamento'),
  new Message('profile_my_reservations', 'Hacer públicas mis reservas'),
  new Message('profile_departments_error', 'Error al obtener los departamentos de su sede'),
  new Message('profile_assistance_parking', 'Solicitar plaza de parking'),
  new Message('profile_assistance_parking_description', 'Reserve automáticamente una plaza de aparcamiento al informar de la presencia en la oficina'),
  new Message('profile_assistance_no_vehicle_for_parking', 'Debes registrar un vehículo para poder solicitar plaza de parking'),
  new Message('profile_assistance_dinner', 'Solicitar turno en el comedor'),
  new Message('profile_assistance_dinner_description', 'Reserve automáticamente una franja horaria en el restaurante al informar de la presencia en la oficina. Si no hay franjas horarias disponibles, seleccione una nueva'),
  new Message('support_preferences_btn', 'Preferencias asistencia'),
  new Message('profile_form_update_error', 'Se ha producido un error en la actualización de asistencia'),
  new Message('profile_form_assistance_config', 'Configuración asistencia'),
  new Message('profile_form_vehicle', 'Tus vehículos'),
  new Message('profile_form_pass_mod', 'Modificar contraseña'),
  new Message('profile_form_vehicle_add', 'Vehiculo añadido correctamente'),
  new Message('profile_form_vehicle_delete', 'Vehiculo eliminado correctamente'),
  new Message('profile_form_vehicle_delete_error', 'Error al eliminar el vehículo'),
  new Message('profile_error_image', 'Error. El tamaño es superior a 5MB'),
  new Message('profile_preferred_time_slot', 'PERIODO DE TIEMPO PREFERIDO'),

    // MODAL INDICATE YOUR DEPT
    new Message('indicate_your_dept_title', 'Indica tu departamento'),
    new Message('indicate_your_dept_desc', 
    'Para poder usar la aplicación, el usuario debe seleccionar el departamento al que pertenece.'),
    new Message('indicate_your_dept_bt_cancel', 'CANCELAR'),
    new Message('indicate_your_dept_bt', 'ACEPTAR'),
    new Message('indicate_your_dept_error', 'Debe seleccionar un departamento'),

    // ASSISTANCE
    new Message('assistance_bottom_previous', 'Anterior'),
    new Message('assistance_bottom_next', 'Siguiente'),
    new Message('assistance_bottom_save', 'GUARDAR'),
    new Message('assistance_bottom_solict', 'SOLICITAR'),
    new Message('assistance_bottom_cancel', 'CANCELAR'),
    new Message('assistance_bottom_cancel_assistance', 'Cancelar asistencia'),
    new Message('assistance_text_confirm', 'Asistencia confirmada'),
    new Message('assistance_text_confirmed', 'Asistentes confirmados'),
    new Message('assistance_text_not_confirm', 'Confirmar asistencia'),
    new Message('assistance_text_not_assign_parking_space', 'No se ha podido asignar plaza de parking para este día'),
    new Message('assistance_text_assign_parking_space', 'Tienes plaza de parking asignada'),
    new Message('assistance_text_not_assign_dinner_space', 'No hay turno libre en el comedor a la hora seleccionada'),
    new Message('assistance_text_assign_dinner_space', 'Turno de comedor reservado a las '),
    new Message('assistance_text_not_request_dinner_space', 'No has solicitado turno de comedor'),
    new Message('assistance_text_not_assign_parking', 'No tienes plaza de parking asignada'),
    new Message('assistance_text_not_assign_parking_text_alert', 'Tienes que registrar un vehículo en tu perfil'),
    new Message('assistance_text_not_assign_vehicle_parking', 'No tienes plaza de {0} asignada'),
    new Message('assistance_text_not_vehicle_assign', 'No tienes vehículo registrado en tu perfil'),
    new Message('assistance_text_not_request_parking', 'No has solicitado plaza de parking'),
    new Message('assistance_text_assign_parking', 'Tienes plaza de {0} asignada'),
    new Message('assistance_text_not_assign_dinner', 'No tienes reservado turno en el comedor'),
    new Message('assistance_text_assign_dinner', 'Tienes reservado turno en el comedor a las '),
    new Message('assistance_text_cancel_ok', 'Asistencia cancelada correctamente'),
    new Message('assistance_text_cancel_ko', 'Error al cancelar la asistencia'),
    new Message('assistance_text_confirm_ok', 'Asistencia confirmada correctamente'),
    new Message('assistance_update_error', 'Error al confirmar la asistencia'),
    new Message('assistance_text_liberate_parking_ok', 'Puesto de parking liberado correctamente'),
    new Message('assistance_text_liberate_parking_ko', 'Error al liberar el puesto de parking'),
    new Message('assistance_text_solict_parking_ok', 'Puesto asignado correctamente'),
    new Message('assistance_text_solict_parking_ko', 'Error en la asignación del puesto'),
    new Message('assistance_text_solict_dinner_ok', 'Puesto comedor asignado correctamente'),
    new Message('assistance_text_solict_dinner_ko', 'Error en la asignación del puesto comedor'),
    new Message('assistance_text_cancel_dinner_ok', 'Puesto comedor cancelado correctamente'),
    new Message('assistance_text_cancel_dinner_ko', 'Error en la cancelación del puesto comedor'),
    new Message('assistance_text_check_daily_menu', 'CONSULTAR MENÚ SEMANAL'),
    new Message('assistance_text_available_shifts', 'TURNOS DISPONIBLES'),
    new Message('assistance_text_legend', 'Leyenda'),
    new Message('assistance_text_reserve_error', 'Ha superado el número máximo de reservas'),
    new Message('assistance_text_turn_selection_error', 'Debes elegir un turno'),
    new Message('assistance_text_not_dining_room_service', 'No hay servicio de comedor para este día'),
    new Message('assistance_text_legend_alert', 'Capacidad excedida. Revise la planificación de presencia en la oficina'),

  // DINNING OCCUPATION
  new Message('occupation_text_free', 'Libre'),
  new Message('occupation_text_occupied', 'Ocupado'),
  new Message('occupation_text_occupancy_level', 'Nivel de ocupación en tiempo real'),
  new Message('occupation_text_last_update', 'Última vez actualizado a las '),

    // PARKING OCCUPATION
    new Message('parking_occupation_title', 'Aparcamiento'),
    new Message('parking_occupation_free_places', 'Plazas libres'),
    new Message('parking_occupation_building_a', 'EDIFICIO A'),
    new Message('parking_occupation_building_b', 'EDIFICIO B'),
    new Message('parking_occupation_schedule', 'Perfil horario de ocupación'),
    new Message('parking_occupation_normal_parking', 'Parking normal'),
    new Message('parking_occupation_electric_parking', 'Parking eléctrico'),
    new Message('parking_occupation_external_error', 'Fallo en servidor Obix al obtener datos de ocupación'),

  // CANTINE
  new Message('cantine_text_error_data', 'Error al obtener los datos')
];
