import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalModule, MsalService, MSAL_CONFIG, MSAL_CONFIG_ANGULAR } from '@azure/msal-angular';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MSALConfigAngularFactory, MSALConfigFactory } from 'src/environments/azure.config';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { APP_VERSION } from './core/constants/const';
import { ConfirmComponent } from './shared/components/confirm_dialog/confirm.component';
import { MyDatePickerModule } from './shared/components/date_picker/datepicker.module';
import { FlashMessagesModule } from './shared/components/flash_messages/flash.module';
import { MenuComponent } from './shared/components/menu/menu.component';
import { ModalErrorMobileComponent } from './shared/components/modal-error-mobile/modal-error-mobile.component';
import { ModalReservationComponent } from './shared/components/modal-reservation/modal-reservation.component';
import { OauthComponent } from './shared/components/oauth.component';
import { SoonPageComponent } from './shared/components/soon-page/soon-page.component';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { AddTableDirective } from './shared/directives/add-table.directive';
import { ClickOutsideModule } from './shared/directives/click-outside/click-outside.module';
import { CustomDatePipe } from './shared/pipes/custom.date.pipe';
import { CustomTimePipe } from './shared/pipes/custom.time.pipe';
import { DateStringPipe } from './shared/pipes/date.string.pipe';
import { MessagesPipe } from './shared/pipes/messages.pipe';
import { AdinService } from './shared/services/adin.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { BaseService } from './shared/services/base.service';
import { BuildingService } from './shared/services/building.service';
import { ConfirmService } from './shared/services/confirm.service';
import { DalkonService } from './shared/services/dalkon.service';
import { DepartmentService } from './shared/services/department.service';
import { EquipementService } from './shared/services/equipement.service';
import { FlashMessagesService } from './shared/services/flash-messages.service';
import { HeadquartersService } from './shared/services/headquarters.service';
import { LoginService } from './shared/services/login.service';
import { NavigatorRefreshService } from './shared/services/navigator.refresh.service';
import { ParkingService } from './shared/services/parking.service';
import { ReservationService } from './shared/services/reservation.service';
import { RoomService } from './shared/services/room.service';
import { UserService } from './shared/services/user.service';
import { WaitingService } from './shared/services/waiting.service';
import { Utils } from './shared/utils/utils';
import { AppVersionComponent } from './views/app-version/app-version.component';
import { BoxOfficeComponent } from './views/box-office/box-office.component';
import { ReservationDeskComponent } from './views/box-office/reservation-desk/reservation-desk.component';
import { DescargasComponent } from './views/descargas/descargas.component';
import { HomeParkingComponent } from './views/home/home-parking.component';
import { HomeComponent } from './views/home/home.component';
import { IncidenceNewFormComponent } from './views/incidence/incidence-new-form/incidence-new-form.component';
import { IncidenceComponent } from './views/incidence/incidence.component';
import { JobPositionComponent } from './views/job-position/job-position.component';
import { ListOfPositionsComponent } from './views/job-position/list-of-positions/list-of-positions.component';
import { MultipleReservationComponent } from './views/job-position/multiple-reservation/multiple-reservation.component';
import { ReservationByPositionComponent } from './views/job-position/reservation-by-position/reservation-by-position.component';
import { ShareMyPositionComponent } from './views/job-position/share-my-position/share-my-position.component';
import { LoginAzureComponent } from './views/login-azure/login-azure.component';
import { LoginSwitcherComponent } from './views/login-switcher/login-switcher.component';
import { LoginComponent } from './views/login/login.component';
import { ParkingOneComponent } from './views/parking-one/parking-one.component';
import { ParkingComponent } from './views/parking/parking.component';
import { ProfileFormComponent } from './views/profile/profile-form/profile-form.component';
import { ProfileComponent } from './views/profile/profile.component';
import { ReservationCardComponent } from './views/reservation/reservation.card.component';
import { ReservationDetailComponent } from './views/reservation/reservation.detail.component';
import { ReservationInvitesComponent } from './views/reservation/reservation.invites.component';
import { ReservationListComponent } from './views/reservation/reservation.list.component';
import { ReservationDialog } from './views/reservation/reservation_dialog/reservation.dialog';
import { ReservationNewDetailComponent } from './views/reservation/reservation_new/reservation.new.detail';
import { ReservationNewDetailByHQ } from './views/reservation/reservation_new_by_headquarters/reservation.new.detail.by.hq';
import { ReservationNewDetailByRoom } from './views/reservation/reservation_new_by_room/reservation.new.detail.room';
import { ReservationResultsByRoomAndPeriodComponent } from './views/reservation/reservation_search_by_room_and_period/reservation.results.by.room.period.component';
import { ReservationSearchByRoomAndPeriodComponent } from './views/reservation/reservation_search_by_room_and_period/reservation.search.by.room.period.component';
import { ReservationSpeedDetailComponent } from './views/reservation/reservation_speed/reservation.speed.detail.component';
import { ReservationSpeedNewDetail } from './views/reservation/reservation_speed/reservation.speed.new.detail';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { RoomAvailableComponent } from './views/room/room.available.component';
import { RoomCardComponent } from './views/room/room.card.component';
import { RoomDetailComponent } from './views/room/room.detail.component';
import { RoomSpeedAvailableComponent } from './views/room/room_speed/room.speed.available.component';
import { RoomSpeedDetailComponent } from './views/room/room_speed/room.speed.detail.component';
import { RoomDetailHeaderComponent } from './views/room/room_without_time/room.detail.header.component';
import { RoomWithoutTime } from './views/room/room_without_time/room.without.time';
import { RoomWithoutTimeDetail } from './views/room/room_without_time/room.without.time.detail';
import { RoomWithoutTimeMosaic } from './views/room/room_without_time/room.without.time.mosaic';
import { SecuritySearchComponent } from './views/security-search/security-search.component';
import { SearchUserPositionComponent } from './views/job-position/search-user-position/search-user-position.component';
import { ValidateTokenComponent } from './views/validate-token/validate-token.component';
import { WaitingDetailComponent } from './views/wating/wating.detail.component';
import { SearchPeopleComponent } from './views/job-position/search-people/search-people.component';
import { AvailablePositionsComponent } from './views/job-position/available-positions/available-positions.component';
import { UserPositionsComponent } from './views/job-position/user-positions/user-positions.component';
import { ModalIndicateDepartmentComponent } from './shared/components/modal-indicate-department/modal-indicate-department.component';
import { DinningPositionComponent } from './views/dinning-position/dinning-position.component';
import { AssistanceComponent } from './views/assistance/assistance.component';
import { ModalEditAssistanceComponent } from './views/assistance/modal-edit-assistance/modal-edit-assistance.component';
import { AssistanceUserComponent } from './views/assistance-user/assistance-user.component';
import { DinningOccupationComponent } from './views/dinning-occupation/dinning-occupation.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { ParkingOccupationComponent } from './views/parking-occupation/parking-occupation.component';
import { CantineComponent } from './views/cantine/cantine.component';

// Languages
registerLocaleData(localeEs, 'es');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEn, 'en');

@NgModule({
  declarations: [
    AppComponent,
    OauthComponent,
    ConfirmComponent,
    LoginComponent,
    MenuComponent,
    HomeComponent,
    ParkingComponent,
    IncidenceComponent,
    WaitingDetailComponent,
    ReservationListComponent,
    ReservationCardComponent,
    ReservationDetailComponent,
    ReservationNewDetailComponent,
    ReservationNewDetailByHQ,
    ReservationNewDetailByRoom,
    ReservationSpeedNewDetail,
    ReservationSpeedDetailComponent,
    RoomAvailableComponent,
    RoomCardComponent,
    RoomDetailComponent,
    ProfileComponent,
    DescargasComponent,
    RoomWithoutTime,
    RoomWithoutTimeDetail,
    ReservationDialog,
    RoomWithoutTimeMosaic,
    RoomSpeedAvailableComponent,
    RoomSpeedDetailComponent,
    CustomDatePipe,
    CustomTimePipe,
    MessagesPipe,
    DateStringPipe,
    HomeParkingComponent,
    ReservationInvitesComponent,
    ValidateTokenComponent,
    ReservationSearchByRoomAndPeriodComponent,
    ReservationResultsByRoomAndPeriodComponent,
    RoomDetailHeaderComponent,
    IncidenceNewFormComponent,
    SoonPageComponent,
    ParkingOneComponent,
    BoxOfficeComponent,
    AddTableDirective,
    ModalReservationComponent,
    ReservationDeskComponent,
    SpinnerComponent,
    ProfileFormComponent,
    SecuritySearchComponent,
    ModalErrorMobileComponent,
    JobPositionComponent,
    ReservationByPositionComponent,
    ListOfPositionsComponent,
    LoginAzureComponent,
    LoginSwitcherComponent,
    ResetPasswordComponent,
    AppVersionComponent,
    ShareMyPositionComponent,
    SearchUserPositionComponent,
    MultipleReservationComponent,
    SearchPeopleComponent,
    AvailablePositionsComponent,
    UserPositionsComponent,
    ModalIndicateDepartmentComponent,
    DinningPositionComponent,
    AssistanceComponent,
    ModalEditAssistanceComponent,
    AssistanceUserComponent,
    DinningOccupationComponent,
    ParkingOccupationComponent,
    CantineComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    ReactiveFormsModule,
    routing,
    FlashMessagesModule,
    MyDatePickerModule,
    BrowserAnimationsModule,
    AutocompleteLibModule,
    InfiniteScrollModule,
    ClickOutsideModule,
    MsalModule,
    ChartsModule
  ],
  providers: [
    BaseService,
    FlashMessagesService,
    ConfirmService,
    AuthenticationService,
    UserService,
    ReservationService,
    EquipementService,
    RoomService,
    WaitingService,
    DalkonService,
    BuildingService,
    DepartmentService,
    LoginService,
    ParkingService,
    Utils,
    NavigatorRefreshService,
    HeadquartersService,
    AdinService,
    ThemeService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useFactory: () =>  {
        const navigatorValue = navigator ? navigator.language.substr(0, 2) : 'es';

        const availableLanguages = ['es', 'en', 'pt'];

        return availableLanguages.includes(navigatorValue) ? navigatorValue : 'es';
    }},
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALConfigAngularFactory
    },
    MsalService,
    {
      provide: APP_VERSION,
      useValue: `${require('../../package.json').version}`
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
