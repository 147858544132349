import { RouterModule, Routes } from '@angular/router';
import { COMPONENT_OAUTH_PATH } from '../environments/environment';
import { MenuGuard } from './core/guards/menu.guard';
import { MenuComponent } from './shared/components/menu/menu.component';
import { OauthComponent } from './shared/components/oauth.component';
import { AppVersionComponent } from './views/app-version/app-version.component';
import { BoxOfficeComponent } from './views/box-office/box-office.component';
import { ReservationDeskComponent } from './views/box-office/reservation-desk/reservation-desk.component';
import { DescargasComponent } from './views/descargas/descargas.component';
import { HomeComponent } from './views/home/home.component';
import { IncidenceComponent } from './views/incidence/incidence.component';
import { AvailablePositionsComponent } from './views/job-position/available-positions/available-positions.component';
import { UserPositionsComponent } from './views/job-position/user-positions/user-positions.component';
import { JobPositionComponent } from './views/job-position/job-position.component';
import { ListOfPositionsComponent } from './views/job-position/list-of-positions/list-of-positions.component';
import { LoginSwitcherComponent } from './views/login-switcher/login-switcher.component';
import { ParkingOneComponent } from './views/parking-one/parking-one.component';
import { ParkingComponent } from './views/parking/parking.component';
import { ProfileComponent } from './views/profile/profile.component';
import { ReservationListComponent } from './views/reservation/reservation.list.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { RoomAvailableComponent } from './views/room/room.available.component';
import { RoomSpeedAvailableComponent } from './views/room/room_speed/room.speed.available.component';
import { RoomWithoutTime } from './views/room/room_without_time/room.without.time';
import { RoomWithoutTimeMosaic } from './views/room/room_without_time/room.without.time.mosaic';
import { SecuritySearchComponent } from './views/security-search/security-search.component';
import { ValidateTokenComponent } from './views/validate-token/validate-token.component';
import { DinningPositionComponent } from './views/dinning-position/dinning-position.component';
import { AssistanceComponent } from './views/assistance/assistance.component';
import { AssistanceUserComponent } from './views/assistance-user/assistance-user.component';
import { DinningOccupationComponent } from './views/dinning-occupation/dinning-occupation.component';
import { ParkingOccupationComponent } from './views/parking-occupation/parking-occupation.component';
import { CantineComponent } from './views/cantine/cantine.component';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

const appRoutes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'validate-token', component: ValidateTokenComponent },
  { path: 'login', component: LoginSwitcherComponent, canActivate: environment['use_azure'] ? [MsalGuard] : [] },
  { path: 'resetPassword', component: ResetPasswordComponent },
  { path: 'app-version', component: AppVersionComponent },
  {
    path: 'menu',
    component: MenuComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'reservations', component: ReservationListComponent, canActivate: [MenuGuard] },
      { path: 'availableRooms', component: RoomAvailableComponent},
      { path: 'parking', component: ParkingComponent, canActivate: [MenuGuard] },
      { path: 'parkingOne', component: ParkingOneComponent, canActivate: [MenuGuard] },
      { path: 'incidence', component: IncidenceComponent, canActivate: [MenuGuard] },
      { path: 'profile', component: ProfileComponent },
      { path: 'security', component: SecuritySearchComponent, canActivate: [MenuGuard]},
      { path: 'descargas', component: DescargasComponent },
      { path: 'room/detail', component: RoomWithoutTime },
      { path: 'room/mosaic', component: RoomWithoutTimeMosaic },
      { path: 'availableSpeedRooms', component: RoomSpeedAvailableComponent },
      { path: 'assistance', component: AssistanceComponent, canActivate: [MenuGuard] },
      { path: 'assistance-user', component: AssistanceUserComponent, canActivate: [MenuGuard] },
      { path: 'cantine', component: CantineComponent, canActivate: [MenuGuard] },
      { path: 'job-position', component: JobPositionComponent, canActivate: [MenuGuard],
        children: [
          { path: '', pathMatch: 'full', component: ListOfPositionsComponent },
          { path: 'select-position/:areaId/:date/:token', component: BoxOfficeComponent },
          { path: 'view-position/:id/:areaId/:date/:token', component: BoxOfficeComponent },
          { path: 'viewMultiple-position/:idList/:areaId/:date/:token', component: BoxOfficeComponent },
          { path: 'selectView-position/:id/:areaId/:date/:token', component: BoxOfficeComponent },
          { path: 'edit-position/:idReservation/:idDesk/:date/:areaId/:token', component: ReservationDeskComponent },
          { path: 'available-positions/:dateFrom/:dateTo/:hotdeskNumber/:hourNumber/:usersList', component: AvailablePositionsComponent },
          { path: 'user-positions/:date/:userId', component: UserPositionsComponent },
          { path: '**', pathMatch: 'full', redirectTo: '' }
      ]},
      { path: 'dinning-position', component: DinningPositionComponent, canActivate: [MenuGuard],
        children: [
          { path: '', pathMatch: 'full', component: ListOfPositionsComponent },
          { path: 'select-position/:areaId/:date/:token', component: BoxOfficeComponent },
          { path: 'view-position/:id/:areaId/:date/:token', component: BoxOfficeComponent },
          { path: 'viewMultiple-position/:idList/:areaId/:date/:token', component: BoxOfficeComponent },
          { path: 'selectView-position/:id/:areaId/:date/:token', component: BoxOfficeComponent },
          { path: 'edit-position/:idReservation/:idDesk/:date/:areaId/:token', component: ReservationDeskComponent },
          { path: 'user-positions/:date/:userId', component: UserPositionsComponent },
          { path: 'available-positions/:dateFrom/:dateTo/:hotdeskNumber/:hourNumber/:usersList', component: AvailablePositionsComponent },
          { path: '**', pathMatch: 'full', redirectTo: '' }
      ]},
    ]
  },
  { path: 'boxOffice/:areaId/:date/:language/:token', component: BoxOfficeComponent },
  { path: 'boxOffice-dinner/:areaId/:date/:language/:token', component: BoxOfficeComponent },
  { path: 'readerBoxOffice/:id/:areaId/:date/:language/:token', component: BoxOfficeComponent },
  { path: 'readerBoxOffice-dinner/:id/:areaId/:date/:language/:token', component: BoxOfficeComponent },
  { path: 'viewMultipleBoxOffice/:idList/:areaId/:date/:language/:token', component: BoxOfficeComponent },
  { path: 'viewMultipleBoxOffice-dinner/:idList/:areaId/:date/:language/:token', component: BoxOfficeComponent },
  { path: 'viewSelectBoxOffice/:id/:areaId/:date/:language/:token', component: BoxOfficeComponent },
  { path: 'viewSelectBoxOffice-dinner/:id/:areaId/:date/:language/:token', component: BoxOfficeComponent },
  { path: 'reservationDesk/:id/:date/:areaId/:language/:token', component: ReservationDeskComponent },
  { path: 'reservationDesk-dinner/:id/:date/:areaId/:language/:token', component: ReservationDeskComponent },
  { path: 'reservationDeskEdit/:idReservation/:idDesk/:date/:areaId/:language/:token', component: ReservationDeskComponent },
  { path: 'reservationDeskEdit-dinner/:idReservation/:idDesk/:date/:areaId/:language/:token', component: ReservationDeskComponent },
  { path: 'dinning-occupation/:token', component: DinningOccupationComponent },
  { path: 'dinning-occupation-headquarter/:headquarterId', component: DinningOccupationComponent },
  { path: 'parking-occupation/:token', component: ParkingOccupationComponent },
  {
    path: `${COMPONENT_OAUTH_PATH}/:token/:expire_in/:refresh`,
    component: OauthComponent
  }
];

export const routing = RouterModule.forRoot(appRoutes);
