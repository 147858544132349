import { Message } from '../message';

export const MESSAGES_EN_LANG = 'en-EN';
export const MESSAGES_EN_US_LANG = 'en-US';
export const MESSAGES_EN_NAV = 'en';

export const MESSAGES_EN = [
  new Message('user', 'User'),
  new Message('password', 'Password'),
  new Message('welcome', 'SmartBuilding'),
  new Message('welcome2', 'BCN Torre Puig'),
  new Message('menu_init', 'Home'),
  new Message('menu_room', 'Room Reservation'),
  new Message('menu_profile', 'Profile'),
  new Message('menu_security', 'Security'),
  new Message('menu_job_position', 'Job position'),
  new Message('menu_dinning_position', 'Restaurant booking'),
  new Message('menu_assistance', 'Office Presence'),
  new Message('menu_assistance_user', 'Team Office Presence'),
  new Message('menu_cantine', 'Restaurant'),
  new Message('menu_logout', 'Logout'),
  new Message('error_getEquipement', 'Error getting room equiment'),
  new Message('error_getAllEquipement', 'Error getting equiment'),
  new Message('error_getRoomDetail', 'Error getting room detail'),
  new Message('rooms_reserve', 'Rooms reserved'),
  new Message('rooms_disable_reservation_title', 'Released rooms'),
  new Message('rooms_disable_reservation_description', 'We have freed the meeting rooms during the COVID-19 crisis'),
  new Message('rooms_reserve_empty', 'There is no room reserved'),
  new Message(
    'rooms_waiting_get_error',
    'Error at getting reservations and waiting list from service'
  ),
  new Message('waiting_list', 'Waiting list'),
  new Message('express_reservation_btn', 'Express reservation {0}'),
  new Message('room_reserve_btn', 'Reserve room'),
  new Message('room_available', 'AVAILABLE'),
  new Message('room_available_btn', 'Reserve'),
  new Message('room_busy', 'BUSY'),
  new Message('room_busy_user', 'BUSY FOR {0}'),
  new Message(
    'express_resevation_success',
    'You have been assigned the room {0} for schedule {1} ({2} - {3})'
  ),
  new Message('incorrect_login', 'Bad login'),
  new Message('azure_token_renewal_error', 'Failed to log into SSO, please close the Pop-up and try again'),
  new Message('error_forbidden', 'Session expired'),
  new Message('error_required', '* Field Required'),
  new Message('error_numeric_required', '* Field Numeric and Required'),
  new Message('error_license_required', '* Registration and required field'),
  new Message('btn_view_rooms', 'View available rooms'),
  new Message('error_nextBooking', 'Error getting the last reservation'),
  new Message('home_events_title', 'NEXT EVENTS'),
  new Message('cancel_reservation_btn', 'Cancel'),
  new Message('reservation_cancelled', 'Reservation cancelled seccessfully'),
  new Message('reservation_cancelled_error', 'Error at cancel reservation'),
  new Message('confirm_dialog_err', 'Error at showing cancel confirmation'),
  new Message(
    'reservation_delete_msg',
    'Are you sure you want to cancel the reseravation?'
  ),
  new Message('reservation_delete_msg_title', 'Reservation cancel'),
  new Message(
    'cancel_waiting_msg',
    'Are you sure you want to cancel the waiting?'
  ),
  new Message('cancel_waiting_msg_title', 'Waiting cancel'),
  new Message('cancel_waiting_successfully', 'Waiting cancelled seccessfully'),
  new Message('cancel_waiting_error', 'Error at cancel waiting'),
  new Message(
    'cancel_waiting_dialog_error',
    'Error at showing cancel confirmation'
  ),
  new Message('express_create_error', 'Error at creating a express reserve'),
  new Message('error_dalkonParking', 'Error at obtain parking'),
  new Message('home_parking_title', 'PARKING'),
  new Message('parking_ceded', 'Ceded'),
  new Message('parking_resident', 'fixed square'),
  new Message('parking_lot', 'Parking space booked for today'),
  new Message('no_parking', 'Without parking space'),
  new Message('no_vehicle', 'Without vehicle'),
  new Message('session_expired', 'Session Expired'),
  new Message('schedule', 'Schedule'),
  new Message('issue', 'Issue'),
  new Message('equipment', 'Equipment'),
  new Message('time_str', 'From {0} to {1}'),
  new Message('date', 'Date'),
  new Message('assistants', 'Assistants'),
  new Message('from', 'From'),
  new Message('since', 'Since'),
  new Message('meeting_reason', 'Reason for meeting'),
  new Message(
    'date_from_greater',
    'Date From must be greater than actual date'
  ),
  new Message('date_to_greater', 'Date To must be greater than Date From'),
  new Message('no_available_rooms_msg', 'There is no available rooms'),
  new Message(
    'no_available_rooms_equipment_msg',
    'With the equipment you need'
  ),
  new Message('rooms_without_equip', 'Rooms without equipment'),
  new Message('available_rooms_list_error', 'Error at getting available rooms'),
  new Message(
    'room_reserved_msg',
    'Room {0} reserved: Add at date {1} since {2} to {3}'
  ),
  new Message('reserve_creation_error', 'Error at create reserve'),
  new Message(
    'room_to_waiting',
    'The reservation has been added to waiting list'
  ),
  new Message(
    'room_to_waiting_error',
    'Error at adding reserve to waiting list'
  ),
  new Message('waiting_msg', '{0} from {1} to {2}'),
  new Message('reservation_reason_placeholder', 'Write a reason'),
  new Message('edit_reservation_msg', 'Reservation updated successfully'),
  new Message('edit_reservation_error', 'Error at reservation edit'),
  new Message('reservation_in_progress', 'In Progress'),
  new Message('name', 'Name'),
  new Message('building', 'Building'),
  new Message('no_building', 'Without building'),
  new Message('select', 'Select..'),
  new Message('all_dept', 'All the departments'),
  new Message('all_user_dept', 'All users in the department: '),
  new Message('save_profile_btn', 'Update profile'),
  new Message('save_building_btn', 'Save building'),
  new Message('buildings', 'Building'),
  new Message('save_building_successfully', 'Building save succesfully'),
  new Message('save_building_error', 'Error at save building'),
  new Message('error_getBuildings', 'Error at obtain building list'),
  new Message('room_detail_name_title', 'Room'),
  new Message('headquarters_list', 'Headquarters'),
  new Message('headquarters', 'Headquarters'),
  new Message('getHeadquarters_error', 'Error getting headquarters'),
  new Message(
    'userWithoutHeadquartersMustSelectOne',
    'Please, select a headquarters to start using the app'
  ),
  new Message('login_access_btn', 'Login'),
  new Message('login_forgot_password', 'I forgot my password'),
  new Message(
    'login_problem_mail',
    'If you have problems accessing the service contact us from '
  ),
  new Message('login_problem_here', 'here'),
  new Message(
    'resetPassword_message_ok',
    'An email with a new password has been sent to you.'
  ),
  new Message(
    'resetPassword_message_error',
    'Error updating password'
  ),
  new Message('resetPassword_request_btn', 'Request'),
  new Message('resetPassword_return_btn', 'Return'),
  new Message('parking_nextDraw_message', 'Next assignment will be '),
  new Message('parking_yieldToday_message', 'Yield today'),
  new Message('parking_yield_message', 'Give place'),
  new Message('parking_next_raffle', 'The next draw will be on'),
  new Message('parking_notVehicle', 'To register your vehicle, you can do it in Your vehicles within Your profile.'),
  new Message('parking_raffle_info', "Today you don't have a parking space"),
  new Message('parking_raffle_info_not_available', 'Draw not available'),
  new Message('parking_enter_raffle', 'Enter the draw'),
  new Message(
    'parking_yieldTemporary_message',
    'Use the option "Temporary yield" for vacation periods or absences. You can determine up to 3 periods in which you want to yield your parking spot.'
  ),
  new Message(
    'parkingOne_yieldTemporary_message',
    'Give in now to leave your place free until the next draw. Use the option "Temporary transfer" for vacation periods or absences. '
  ),
  new Message(
    'parkingOne_checkedRaffle_title',
    'You are participating in the draw'
  ),
  new Message(
    'parkingOne_checkedRaffle_description',
    'As long as you keep this option active, every day you will enter the lottery for parking spaces. Remember to deactivate it during vacation or absences. '
  ),
  new Message(
    'parkingOne_no_checkedRaffle_title',
    'You are not participating in the draw'
  ),
  new Message(
    'parkingOne_checkedRaffle_description_second',
    'If you want to know how the lottery works, press'
  ),
  new Message(
    'parkingOne_raffle_end',
    'Error, the giveaway is deleted or disabled'
  ),
  new Message(
    'parking_without_assigned_parkingSpot',
    "You don't have an assigned parking spot today"
  ),
  new Message(
    'parking_with_assigned_parkingSpot_today',
    'Your parking spot for today is '
  ),
  new Message(
    'parking_yielded_parkingSpot',
    'Your parking spot is yielded until '
  ),
  new Message(
    'parking_assigned_parkingSpot',
    'You have a parking spot assigned'
  ),
  new Message('parking_assigned_fixed_parkingSpot', 'You have a fixed place assigned '),
  new Message('parking_assigned_temporary_parkingSpot', 'You have temporary place assigned until '),
  new Message('parking_request_seeDetails_btn', 'See details'),
  new Message('parking_request_btn', 'Request parking spot'),
  new Message(
    'parking_parkingSpot_absence',
    'Your can yield your parking spot when on absence or vacation'
  ),
  new Message('parking_user_message', 'Place assigned until next raffle'),
  new Message('parking_temporal_message', 'Place temporarily ceded'),
  new Message('parking_yielded_parkingRecover', 'You will recover your place on '),
  new Message('parking_yielded_parkingConfirm', 'You have canceled the transfer of your place'),
  new Message(
    'parking_request_periods',
    'To request parking spot, select up to 3 periods'
  ),
  new Message('parking_yield_temporary', 'Temporary yield'),
  new Message('parking_from_date', 'From'),
  new Message('parking_to_date', 'Until'),
  new Message('parking_disabled_reservation_title', 'Parking released'),
  new Message('parking_disabled_reservation_description', 'We have released the car park during the COVID-19 crisis'),

  new Message('send_invite_btn', 'Invite'),
  new Message('send_invitation', 'Send invitation'),
  new Message('user_to_send_invite', 'Search users…'),
  new Message('search_user', 'Search person'),
  new Message(
    'search_users_to_send_invitation',
    'Choose the users you want to send the invitation to:'
  ),
  new Message('invitation_user_not_found', 'No users found'),
  new Message('invitation_sent', 'The invitation has been sent successfully'),
  new Message(
    'invitations_users_search_error',
    'A problem has occurred while searching for users, please try again.'
  ),
  new Message(
    'invitations_users_department_search_error',
    'There was a problem searching by user or department, please try again'
  ),
  new Message(
    'invitations_users_send_error',
    'There was a problem sending the invitation, please try again.'
  ),
  new Message('invitations_user_not_registered', 'User not registered'),
  new Message('reservation_pending_confirmation', 'Confirmation pending'),
  new Message('room_reserve_byRoomAndPeriod_btn', 'Search by room and period'),
  new Message(
    'search_reservations_periods_by_room',
    'Finding times for a room'
  ),
  new Message('hours', 'Hours'),
  new Message('time_str_from', 'From'),
  new Message('time_str_to', 'To'),
  new Message('floor', 'Plant'),
  new Message('select_floor', 'Select plant'),
  new Message('area', 'Space'),
  new Message('select_area', 'Select space'),
  new Message('resource', 'Resource'),
  new Message('select_resource', 'Select resource'),
  new Message('categorie', 'Category'),
  new Message('select_categorie', 'Select category'),
  new Message('observation', 'Observations'),
  new Message('send_report', 'Send report'),
  new Message('title_incidence', 'Report incident'),
  new Message('box_title', 'Select a position to reserve it'),
  new Message('box_title_legend', 'Legend'),
  new Message('box_free', 'Free'),
  new Message('box_partially_occupied', 'Partially busy'),
  new Message('box_occupied', 'Occupied'),
  new Message('box_disabled', 'Disabled'),
  new Message('box_reserved', 'Reserved'),
  new Message('box_yourDesk', 'Your Desk'),
  new Message('box_userSelectDesk', 'Selected user position'),
  new Message('box_position_available', 'Selected positions'),
  new Message('reserveDesk_title', 'Reserve job'),
  new Message('reserveDeskDinning_title', 'Reserve dinning'),
  new Message('reserveDesk_busy', 'Busy'),
  new Message('reserveDesk_toSelect', 'TO SELECT'),
  new Message('reserveDesk_selected', 'SELECTED'),
  new Message('reserveDesk_cancel', 'CANCEL'),
  new Message('reserveDesk_reserve', 'RESERVE'),
  new Message('reserveDesk_reserve_all', 'FULL WORKING DAY'),
  new Message('reserveDesk_edit', 'EDIT'),

  new Message('message_DeskNotSelected_Title', 'Cannot be selected'),
  new Message('message_DeskConsecutive_Description', 'Only consecutive time slots can be booked.'),
  new Message('message_DeskNot_Description', 'No more time slots can be selected.'),

  new Message('message_DeskNotSelected_Title--NotSelect', 'Cannot be reserved'),
  new Message('message_DeskConsecutive_Description--NotSelect', 'To make the reservation you must select at least one time slot.'),

   // Security
   new Message('security_search_title', 'Search by'),
   new Message('security_search_title', 'Select an option'),
   new Message('security_search_name', 'Name'),
   new Message('security_search_email', 'Email'),
   new Message('security_search_code', 'Seat code'),
   new Message('security_search_model', 'Model'),
   new Message('security_search_plate', 'Plate'),
   new Message('security_search_place', 'Place number'),
   new Message('security_search_building', 'Building'),
   new Message('security_search_floor', 'Floor'),
   new Message('security_search_btn', 'Search'),
   new Message('security_search_value', 'Text'),
   new Message('security_search_value_error', 'Please, enter at least 3 characters'),
   new Message('security_search_by_name', 'There are no results for that name'),
   new Message('security_search_by_plate', 'There are no results for that plate'),
   new Message('security_search_by_place', 'There are no results for that place'),
   new Message('security_search_service_error', 'Oops, an unexpected error occurred'),
   new Message('security_search_results', 'Results'),

  // Reservation by position
  new Message('reservation_by_position_title', 'Reserve a position'),
  new Message('reservation_by_position_btn', 'Reservation'),
  new Message('reserved_seats', 'Reserved seats'),
  new Message('no_reserved_seats', 'You have no reserved positions'),
  new Message('reservation_recover_desk_error', 'Error retrieving reserved seats'),
  new Message('reservation_desk_success', 'Your seat has been successfully reserved.'),
  new Message('reservation_desk_error', 'An error occurred during the reservation.'),
  new Message('reservation_checkin_success', 'Check-in was successful.'),
  new Message('reservation_checkin_error', 'Check-in could not be done.'),
  new Message('reservation_checkout_success', 'Check-out was successful.'),
  new Message('reservation_checkout_error', 'Check-out could not be done.'),
  new Message('reservation_not_available', 'Could not book. You already have a seat reservation on the same date.'),
  new Message('reservation_give', 'Cancel'),
  new Message('reservation_edit', 'Edit'),
  new Message('reservation_checkin', 'Check-In'),
  new Message('reservation_checkout', 'Check-Out'),
  new Message('reservation_edit_success', 'Your reservation has been modified successfully.'),
  new Message('reservation_edit_error', 'An error occurred during modification.'),
  new Message('reservation_cancel_success', 'Your reservation has been cancelled successfully.'),
  new Message('reservation_cancel_error', 'An error occurred during the process.'),
  new Message('reservation_historical_title', 'History'),
  new Message('reservation_historical_description', 'You dont have finalized reservations yet'),
  new Message('reservation_permissions', 'You cannot make any more reservations in this period.'),
  new Message('reservation_load_more', 'Load more'),
  new Message('reservation_share', 'Share'),
  new Message('reservation_add_people', 'Add people'),
  new Message('reservation_by', 'by '),
  new Message('reservation_busy_by', 'OCCUPIED BY'),
  new Message('reservation_by_admin', 'Reserved by '),
  new Message('reservation_menu_user', 'Search user position'),
  new Message('reservation_menu_multiple', 'Multiple booking'),
  new Message('reservation_menu_position', ' Reserve position'),
  new Message('reservation_user_position_date', 'Date'),
  new Message('reservation_user_position_see_reservations', 'See reservation'),
  new Message('reservation_multiple_tab1', 'Data'),
  new Message('reservation_multiple_tab2', 'People'),
  new Message('reservation_multiple_number_of_positions', 'position no'),
  new Message('reservation_multiple_number_of_hours', 'Hours'),
  new Message('reservation_multiple_number_of_minutes', 'Minutes'),
  new Message('reservation_multiple_start_date', 'Start date'),
  new Message('reservation_multiple_end_date', 'End date'),
  new Message('reservation_multiple_see_positions', 'See available positions'),
  new Message('reservation_share_success', 'Your position has been shared successfully'),
  new Message('reservation_share_error', 'Error, your position could not be shared'),
  new Message('reservation_no_available_positions', 'There are no positions available for that selection'),
  new Message('reservation_available_positions_error', 'Error getting available seats'),
  new Message('reservation_multiple_desk_success', 'Your multiple reservation has been successful'),
  new Message('reservation_search_user', 'Person'),

  new Message('profile_email_required', 'The email must be valid'),
  new Message('profile_building_selected', 'The building is mandatory'),
  new Message('profile_password_required', 'The password is mandatory'),
  new Message(
    'profile_newpassword_required',
    'The new password is mandatory'
  ),
  new Message(
    'profile_newpasswordrepeat_required',
    'You must repeat the new password'
  ),
  new Message(
    'profile_newpasswordrepeat_notSame',
    'Passwords do not match'
  ),
  new Message('getBuildings_error', 'Failed to get the buildings'),
  new Message('updateProfile_error', 'Profile update failed'),
  new Message('updateProfile_success', 'Profile successfully updated'),
  new Message('updatePassword_success', 'Password updated successfully'),
  new Message(
    'updatePassword_error',
    'An error occurred while changing the password.'
  ),

  // Incidences
  new Message('incidence_file_type', 'Error. The selected file is not an image'),
  new Message('user_image', 'Picture'),
  new Message('user_image_optional', '(Optional)'),
  new Message('no_incidences', 'No incidents have been created'),
  new Message('user_image_select_file', 'Select file'),
  new Message('user_image_no_file', 'No chosen file'),
  new Message('user_image_file_charge', 'Click to upload file'),

  // Download
  new Message('dowloads_android_title', 'Vodafone Plaza App for Android'),
  new Message(
    'dowloads_android_desc',
    'You must authorize the installation in "Settings", "Advanced settings", "Security" and activate "Apps of unknown origin"'
  ),
  new Message('dowloads_btn', 'Download'),
  new Message('dowloads_ios_title', 'Vodafone Plaza App for iOS'),
  new Message(
    'dowloads_ios_desc',
    'You will receive a notice that the application comes from an unidentified developer, you must accept the installation anyway (Trust the service provider)'
  ),
  new Message('dowloads_android_tower_title', 'Tower App for Android'),
  new Message('dowloads_ios_tower_title', 'Tower App for iOS'),

  // Azure
  new Message('login_with_azure', 'Login with SSO'),

  // Profile
  new Message('change_password_btn', 'Change password'),
  new Message('my_vehicles_btn', 'My vehicles'),
  new Message('update_password_ok', 'Password updated correctly'),
  new Message('update_password_fail', 'Error, the password introduced does not match your password'),
  new Message('update_password_error', 'Error, it was not possible to update the password'),
  new Message('profile_vehicle_main', 'Main'),
  new Message('profile_vehicle_edit', 'Edit'),
  new Message('profile_vehicle_remove', 'Remove'),
  new Message('profile_principal_vehicle', 'Correctly assigned primary vehicle'),
  new Message('profile_edit_vehicle', 'Vehicle edited correctly'),
  new Message('profile_edit_vehicle_error', 'You must select other vehicle as main previously'),
  new Message('profile_vehicleEdit_type_title', 'VEHICLE TYPE'),
  new Message('profile_vehicleEdit_type_select', 'Select type'),
  new Message('profile_vehicleEdit_license_title', 'ENROLLMENT'),
  new Message('profile_vehicleEdit_license_placeholder', 'Enter the license plate'),
  new Message('profile_vehicleEdit_model_title', 'MODEL'),
  new Message('profile_vehicleEdit_model_placeholder', 'Enter the model'),
  new Message('profile_vehicleEdit_color_title', 'COLOR'),
  new Message('profile_vehicleEdit_color_placeholder', 'Enter the color'),
  new Message('profile_vehicleEdit_main_title', 'MAIN VEHICLE'),
  new Message('profile_vehicleEdit_add', 'Add vehicle'),
  new Message('profile_vehicleEdit_edit', 'Edit vehicle'),
  new Message('profile_vehicleEdit_cancel', 'Cancel'),
  new Message('profile_department', 'Department'),
  new Message('profile_my_reservations', 'Make my reservations public'),
  new Message('profile_departments_error', 'Failed to get departments from your headquarters'),
  new Message('profile_assistance_parking', 'Booking parking space'),
  new Message('profile_assistance_parking_description', 'Book automatically a parking space when informing the office presence'),
  new Message('profile_assistance_no_vehicle_for_parking', 'You have to register a vehicle in order to request a parking space'),
  new Message('profile_assistance_dinner', 'Booking restaurant'),
  new Message('profile_assistance_dinner_description', 'Book automatically a time slot in the restaurant when informing the office presence. If there are no available time slots, please select a new one'),
  new Message('support_preferences_btn', 'Office Presence Preferences'),
  new Message('profile_form_update_error', 'Support update failed'),
  new Message('profile_form_assistance_config', 'Office presence settings'),
  new Message('profile_form_vehicle', 'Your vehicles'),
  new Message('profile_form_pass_mod', 'Modify password'),
  new Message('profile_form_vehicle_add', 'Vehicle successfully added'),
  new Message('profile_form_vehicle_delete', 'Vehicle removed successfully'),
  new Message('profile_form_vehicle_delete_error', 'Failed to remove vehicle'),
  new Message('profile_error_image', 'Error. Size is greater than 5MB'),
  new Message('profile_preferred_time_slot', 'PREFERRED TIME SLOT'),

    // MODAL INDICATE YOUR DEPT
    new Message('indicate_your_dept_title', 'Indicate your department'),
    new Message('indicate_your_dept_desc', 
    'In order to use the application, the user must select the department to which it belongs.'),
    new Message('indicate_your_dept_bt_cancel', 'CANCEL'),
    new Message('indicate_your_dept_bt', 'ACCEPT'),
    new Message('indicate_your_dept_error', 'You must select a department'),

    // ASSISTANCE
    new Message('assistance_bottom_previous', 'Previous'),
    new Message('assistance_bottom_next', 'Next'),
    new Message('assistance_bottom_save', 'SAVE'),
    new Message('assistance_bottom_solict', 'REQUEST'),
    new Message('assistance_bottom_cancel', 'CANCEL'),
    new Message('assistance_bottom_cancel_assistance', 'Cancel office presence'),
    new Message('assistance_text_confirm', 'Confirmed office presence'),
    new Message('assistance_text_confirmed', 'Confirmed attendees'),
    new Message('assistance_text_not_confirm', 'Confirm office presence'),
    new Message('assistance_text_not_assign_parking_space', 'It was not possible to assign a parking space for this day'),
    new Message('assistance_text_assign_parking_space', 'You have assigned parking space'),
    new Message('assistance_text_not_assign_dinner_space', 'There is no free time slot in the restaurant at the selected time'),
    new Message('assistance_text_assign_dinner_space', 'Restaurant time slot booked at '),
    new Message('assistance_text_not_request_dinner_space', 'You have not booked a restaurant time slot'),
    new Message('assistance_text_not_assign_parking', `You haven't booked parking space`),
    new Message('assistance_text_not_assign_parking_text_alert', 'You have to register a vehicle in your profile'),
    new Message('assistance_text_not_assign_vehicle_parking', `You haven't booked {0} space`),
    new Message('assistance_text_not_vehicle_assign', 'You do not have a vehicle registered in your profile'),
    new Message('assistance_text_not_request_parking', 'You have not booked a parking space'),
    new Message('assistance_text_assign_parking', 'You have booked a {0} parking space'),
    new Message('assistance_text_not_assign_dinner', `You haven't booked time slot in the restaurant`),
    new Message('assistance_text_assign_dinner', 'You have booked time slot in the restaurant at '),
    new Message('assistance_text_cancel_ok', 'Office Presence successfully canceled'),
    new Message('assistance_text_cancel_ko', 'Failed to cancel office presence'),
    new Message('assistance_text_confirm_ok', 'Successfully confirmed office presence'),
    new Message('assistance_update_error', 'Failed to confirm office presence'),
    new Message('assistance_text_liberate_parking_ok', 'Parking space released correctly'),
    new Message('assistance_text_liberate_parking_ko', 'Error when releasing the parking space'),
    new Message('assistance_text_solict_parking_ok', 'Correctly assigned position'),
    new Message('assistance_text_solict_parking_ko', 'Position assignment error'),
    new Message('assistance_text_solict_dinner_ok', 'Correctly assigned restaurant position'),
    new Message('assistance_text_solict_dinner_ko', 'Error in the assignment of the restaurant position'),
    new Message('assistance_text_cancel_dinner_ok', 'Restaurant position successfully canceled'),
    new Message('assistance_text_cancel_dinner_ko', 'Error in the cancellation of the restaurant shift'),
    new Message('assistance_text_check_daily_menu', 'CHECK DAILY MENU'),
    new Message('assistance_text_available_shifts', 'SHIFTS AVAILABLE'),
    new Message('assistance_text_legend', 'Legend'),
    new Message('assistance_text_reserve_error', 'You have exceeded the maximum number of reservations'),
    new Message('assistance_text_turn_selection_error', 'You have to select a time slot'),
    new Message('assistance_text_not_dining_room_service', `There's no restaurant service for this day`),
    new Message('assistance_text_legend_alert', 'Capacity exceeded. Please review the office presence planning'),

    // DINNING OCCUPATION
    new Message('occupation_text_free', 'Free'),
    new Message('occupation_text_occupied', 'Occupied'),
    new Message('occupation_text_occupancy_level', 'Real-time occupancy ratio'),
    new Message('occupation_text_last_update', 'Last updated at '),

    // PARKING OCCUPATION
    new Message('parking_occupation_title', 'Parking'),
    new Message('parking_occupation_free_places', 'Free places'),
    new Message('parking_occupation_building_a', 'BUILDING A'),
    new Message('parking_occupation_building_b', 'BUILDING B'),
    new Message('parking_occupation_schedule', 'Occupancy schedule profile'),
    new Message('parking_occupation_normal_parking', 'Normal parking'),
    new Message('parking_occupation_electric_parking', 'Electric parking'),
    new Message('parking_occupation_external_error', 'Obix server crash when obtaining occupancy data'),

    // CANTINE
    new Message('cantine_text_error_data', 'Failed to get data')
];
